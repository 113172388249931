import { computed, ref } from 'vue';
import { useModelDialog } from '@/hooks/useModelDialog';
import {
  StandartizeApi,

} from '@/service/standartizeService';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useActiveTable } from '@/components/activeTable/useActiveTable';
import { Region, useCompanies } from '@/hooks/useCompanies';
import { StandartizeFile, StandartizeFileUploadModel } from '@/service/standartize/types';

type FormModel = {
  mark: string;
  index_col: string;
  source_file: File;
  region: string;
  kind: 1|2|11;
  fias_col: string;
  address_col: string;

  col_1: string;
  col_2: string;
  col_3: string;
  col_4: string;
  col_5: string;
  col_6: string;
  col_7: string;
  col_8: string;
  col_9: string;
  col_10: string;
  col_11: string;
}

export const useFileUploadDialog = () => {
  const { fetchRegions } = useCompanies();

  // const {
  //   records: regions,
  // } = useActiveTable<Region, Region, 'id'>({
  //   keyField: 'id',
  //   // @TODO удалить после апдейта бэка
  //   defaultLimit: ref(10000),
  //   async fetch({ params, signal }) {
  //     const { response } = await fetchRegions({ ...params, signal });
  //
  //     return response;
  //   },
  // });

  const _fetchRegions = () => fetchRegions({
    limit: 10000,
    page: 1,
  }).then((r) => {
    if (!r.status) return [];
    return r.response.results;
  });

  const fileUploadDialog = useModelDialog<
    FormModel,
    StandartizeFile
    >({
      fields: [
        {
          key: 'mark',
          field: 'mark',
          type: ActiveFormFieldType.input,
          options: {
            label: 'Метка',
          },
        },
        {
          key: 'region',
          field: 'region',
          type: ActiveFormFieldType.searchSelect,
          options: {
            label: 'Регион',
            prefetch: true,
            displayField: 'full_name',
            valueField: 'code',
            searchQuery: _fetchRegions,
          },
        },
        {
          key: 'source_file',
          field: 'source_file',
          type: ActiveFormFieldType.file,
          options: {
            label: 'Файл',
          },
        },
        {
          key: 'kind',
          field: 'kind',
          type: ActiveFormFieldType.select,
          defaultValue: 1,
          options: {
            label: 'Тип',
            options: [
              {
                label: 'Адрес',
                value: 1,
              },
              {
                label: 'Адрес (без кода ФИАС)',
                value: 2,
              },
              {
                label: 'Код ФИАС',
                value: 11,
              },
            ],
          },
        },
        {
          key: 'fias_col',
          field: 'fias_col',
          type: ActiveFormFieldType.input,
          checkHidden: (model) => model.kind === 1 || model.kind === 2,
          options: {
            label: 'Номер колонки - ФИАС (обязательно)',
          },
        },
        {
          key: 'address_col',
          field: 'address_col',
          type: ActiveFormFieldType.input,
          checkHidden: (model) => model.kind === 11 || model.kind === 2,
          options: {
            label: 'Номер колонки - адрес (обязательно)',
          },
        },
        {
          key: 'col_1',
          field: 'col_1',
          type: ActiveFormFieldType.input,
          checkHidden: (model) => model.kind === 1,
          options: {
            label: 'Номер колонки - Район',
          },
        },
        {
          key: 'col_2',
          field: 'col_2',
          type: ActiveFormFieldType.input,
          checkHidden: (model) => model.kind === 1,
          options: {
            label: 'Номер колонки - Город',
          },
        },
        {
          key: 'col_3',
          field: 'col_3',
          type: ActiveFormFieldType.input,
          checkHidden: (model) => model.kind === 1,
          options: {
            label: 'Номер колонки - Населенный пункт',
          },
        },
        {
          key: 'col_4',
          field: 'col_4',
          type: ActiveFormFieldType.input,
          checkHidden: (model) => model.kind === 1,
          options: {
            label: 'Номер колонки - Микрорайон',
          },
        },
        {
          key: 'col_5',
          field: 'col_5',
          type: ActiveFormFieldType.input,
          checkHidden: (model) => model.kind === 1,
          options: {
            label: 'Номер колонки - Улица',
          },
        },
        {
          key: 'col_6',
          field: 'col_6',
          type: ActiveFormFieldType.input,
          checkHidden: (model) => model.kind === 1,
          options: {
            label: 'Номер колонки - Номер дома',
          },
        },
        {
          key: 'col_7',
          field: 'col_7',
          type: ActiveFormFieldType.input,
          checkHidden: (model) => model.kind === 1,
          options: {
            label: 'Номер колонки - Корпус дома',
          },
        },
        {
          key: 'col_8',
          field: 'col_8',
          type: ActiveFormFieldType.input,
          checkHidden: (model) => model.kind === 1,
          options: {
            label: 'Номер колонки - Строение дома',
          },
        },
        {
          key: 'col_9',
          field: 'col_9',
          type: ActiveFormFieldType.input,
          checkHidden: (model) => model.kind === 1,
          options: {
            label: 'Номер колонки - Номер Квартира',
          },
        },
        {
          key: 'col_10',
          field: 'col_10',
          type: ActiveFormFieldType.input,
          checkHidden: (model) => model.kind === 1,
          options: {
            label: 'Номер колонки - Литера квартиры',
          },
        },
        {
          key: 'col_11',
          field: 'col_11',
          type: ActiveFormFieldType.input,
          checkHidden: (model) => model.kind === 1,
          options: {
            label: 'Номер колонки - Номер комнаты',
          },
        },
      ],
      title: 'Загрузка файла',
      onModelUpdate: async (payload) => {
        const errorsMap = {} as Record<keyof FormModel, string[]>;

        if (!payload.mark) {
          errorsMap.mark = ['Поле обязательно'];
        }
        if (!payload.source_file) {
          errorsMap.source_file = ['Поле обязательно'];
        }

        if (payload.kind === 11 && !payload.fias_col) {
          errorsMap.fias_col = ['Поле обязательно'];
        }

        if (payload.kind === 1 && !payload.address_col) {
          errorsMap.address_col = ['Поле обязательно'];
        }

        if (Object.keys(errorsMap).length) {
          return {
            status: false,
            response: errorsMap,
          };
        }

        let usecols = payload.kind === 1
          ? [String(+payload.address_col - 1)]
          : [
            payload.fias_col,
            payload.col_9,
            payload.col_10,
            payload.col_11,
          ].filter((v) => !!v).map((v) => String((+v) - 1));

        const visualcols = payload.kind === 1
          ? [] : [
            payload.col_1,
            payload.col_2,
            payload.col_3,
            payload.col_4,
            payload.col_5,
            payload.col_6,
            payload.col_7,
            payload.col_8,
            payload.col_9,
            payload.col_10,
            payload.col_11,
          ].filter((v) => !!v).map((v) => String((+v) - 1));

        if (payload.kind === 2) {
          usecols = [...visualcols];
        }

        return StandartizeApi.file.upload(
          {
            ...payload,
            ...(!payload.region ? { prefix: '.', region: '0' } : {}),
            usecols,
            visualcols,
          },
        );
      },
    });

  return fileUploadDialog;
};
