import { StandartizeAutomatitionSettings, UserPrefs, useUserPrefs } from '@/hooks/useUserPrefs';
import {
  ref, watch, onBeforeUnmount, onMounted, computed,
} from 'vue';
import { clearInterval } from 'timers';

export const useAutomation = (doStandardization: (settings: StandartizeAutomatitionSettings) => void) => {
  const { userPrefs, updateUserPrefs } = useUserPrefs();

  const model = computed(
    () => userPrefs.value?.standartize_preferences ?? { enabled: false, type: 'page', minutes: 10 },
  );

  let timerId: number;

  onBeforeUnmount(
    () => {
      clearInterval(timerId);
    },
  );

  const isAutomatizing = ref(false);

  watch(() => userPrefs.value.standartize_preferences, async (prefs) => {
    console.warn('userPrefs.value.standartize_preferences', prefs);
    if (!prefs) {
      return;
    }
    const { enabled } = prefs;
    if (timerId) {
      clearInterval(timerId);
    }
    if (enabled) {
      if (!isAutomatizing.value) {
        isAutomatizing.value = true;
        await doStandardization(model.value);
        isAutomatizing.value = false;
      }
      setInterval(async () => {
        if (!prefs.enabled) {
          return;
        }
        if (isAutomatizing.value) {
          return;
        }
        isAutomatizing.value = true;
        await doStandardization(model.value);
        isAutomatizing.value = false;
      }, 1000 * 60 * model.value.minutes);
    }
  }, { immediate: true });

  // watch(userPrefs, (prefs: UserPrefs) => {
  //   if (prefs.standartize_preferences) {
  //     model.value = (prefs.standartize_preferences || { enabled: false, type: 'page' }) as StandartizeAutomatitionSettings;
  //   }
  // }, { immediate: true });

  const updateModel = async (m: StandartizeAutomatitionSettings) => {
    updateUserPrefs({
      standartize_preferences: m,
    });
  };
  return [model, updateModel];
};
