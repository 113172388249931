import { useModelDialog } from '@/hooks/useModelDialog';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { StandartizeApi } from '@/service/standartizeService';

export const useEditCourtModal = () => useModelDialog<
    { address: string },
    { address: string },
    { id: number; info: { address: string }}
  >({
    getForm: async (court) => ({
      status: true,
      response: { address: court.info.address },
    }),
    title: 'Редактирование адреса суда',
    fields: [
      {
        key: 'address',
        field: 'address',
        label: 'Адрес',
        type: ActiveFormFieldType.input,
        options: {
          label: 'Адрес',
        },
      },
    ],
    onModelUpdate: async (model, payload) => {
      const response = await StandartizeApi.mirCourt.updateAddress({
        id: payload.id,
        address: model.address,
      });
      if (response.status) {
        return {
          status: true,
          response: {
            address: model.address,
          },
        };
      }
      return response;
    },
  });
