import { Entries } from '@/types/utils';
import { StandartizeAddressColor, StandartizeAddressQuery } from '@/service/standartize/types';

type StandartizeTableFiltersModel = {
  standardization_file: number;
  name: string;
  cadnum_quality: { min: number; max: number }|null;
  cadnum_sources: number[]|null;
  is_verified__result : boolean|null;
  is_setted____result : boolean|null;
  is_verified__reg_court : boolean|null;
  is_setted__reg_court : boolean|null;
  is_verified__mir_court : boolean|null;
  is_setted__mir_court : boolean|null;
  is_verified__fssp : boolean|null;
  is_setted__fssp : boolean|null;
  is_setted__street : boolean|null;
  is_setted__house : boolean|null;
  is_setted__flat : boolean|null;
  is_setted__postalcode : boolean|null;
  search_cadnums: boolean|undefined;
  has_failed_sources: boolean;
  color: StandartizeAddressColor|'true'|'false';
}

const entryGuard = <T extends string & keyof StandartizeTableFiltersModel>(
  payload: [key: string, value: StandartizeTableFiltersModel[T]], key: T,
): payload is [key: T, value: StandartizeTableFiltersModel[T]] => payload[0] === key;

export const mapStandartizeFiltersToQuery = (
  model: StandartizeTableFiltersModel,
) => {
  const entries = (Object.entries(model) as Entries<StandartizeTableFiltersModel>);
  return entries.reduce((
    acc,
    entry: [string, any],
  ) => {
    if (entry[1] !== null) {
      if (entryGuard(
        entry,
        'color',
      )) {
        const [key, value] = entry;
        if (value === 'true' || value === 'false') {
          acc.color_isnull = value;
        } else {
          acc[key] = value;
        }
      } else if (entryGuard(
        entry,
        'has_failed_sources',
      )) {
        const [key, value] = entry;
        acc[key] = value;
      } else if (entryGuard(
        entry,
        'cadnum_sources',
      )) {
        const [key, value] = entry;
        acc[key] = value;
      } else if (entryGuard(
        entry,
        'cadnum_quality',
      )) {
        const [key, value] = entry;
        if (value) {
          const { min, max } = value;
          acc.cadnum_quality_lte = max;
          acc.cadnum_quality_gte = min;
        }
      } else if (entryGuard(
        entry,
        'standardization_file',
      )) {
        const [key, value] = entry;
        acc[key] = value;
      } else if (entryGuard(
        entry,
        'name',
      )) {
        const [key, value] = entry;
        acc[key] = value;
      } else if (entryGuard(entry, 'search_cadnums')) {
        const [key, value] = entry;
        acc[key] = value;
      } else {
        const [key, value] = entry;
        const [filterPrefix, filterValue] = key.split('__');
        const filterPrefixPrepared = (value === false
          ? `${filterPrefix}_and_not`
          : `${filterPrefix}_and`) as keyof Omit<StandartizeAddressQuery, 'standardization_file'|'cadnum_quality_lte'|'cadnum_quality_gte'|'cadnum_sources'|'name'|'search_cadnums'|'has_failed_sources'|'color'|'color_isnull'>;
        if (!acc[filterPrefixPrepared]) {
          acc[filterPrefixPrepared] = [];
        }
        acc[filterPrefixPrepared].push(filterValue);
      }
    }
    return acc;
  }, {} as StandartizeAddressQuery);
};
