import { ListingRequestSource, ListingResponse } from '@/store/modules/api';
import { ApiResponse } from '@/service/api';
import { Ref } from 'vue';
import { useQuery } from 'vue-query';
import { useToast } from '@/hooks/useToast';

type Get<Resource> = (
  model: ListingRequestSource<{ regioncode: string }>,
) => Promise<ApiResponse<ListingResponse<Resource>>>

const CACHE_TIME = 1000 * 60 * 60 * 24;

export const useSearchCommonSimple = <Resource>(
  cacheKey: string,
  queryFn: () => Promise<Resource[]>,
) => {
  const queryOptions = {
    queryKey: [cacheKey],
    queryFn,
    staleTime: CACHE_TIME,
    refetchInterval: CACHE_TIME,
    cacheTime: CACHE_TIME,
  };
  const { data } = useQuery(queryOptions);
  return {
    records: data,
  };
};

export const useSearchCommon = <Resource extends { regioncode: string }>(
  cacheKey: string,
  regionCode: Ref<string | null>,
  fetch: Get<Resource>,
) => {

  const { showPureDangerToast } = useToast();
  const promiseMap: Record<string, Promise<Resource[]>> = {};

  async function queryFn() {
    const promise = fetch({
      limit: 10000,
      offset: 0,
      page: 1,
      filters: { regioncode: regionCode.value },
    }).then(async (result) => {
      if (!result.status) {
        console.error('Failed to fetch', { regionCode: regionCode.value });
        const statusCode = result.statusCode;
        await showPureDangerToast({
          duration: 8000,
          message: 'pure',
          params: { message: `Ошибка получения данных: ${statusCode ?? ''}` },
        });
        return [];
      }
      return result.response.results;
    });
    promiseMap[regionCode.value ?? ''] = promise;
    return promise;
  }

  const queryOptions = {
    queryFn,
    queryKey: [cacheKey, regionCode],
    staleTime: CACHE_TIME,
    refetchInterval: CACHE_TIME,
    cacheTime: CACHE_TIME,
  };

  const { data } = useQuery(queryOptions);

  return {
    records: data as Ref<Array<Resource>> ?? [],

    async awaitRecords() { // todo remove this, used in AddAddressDialog
      const promise = promiseMap[regionCode.value ?? ''];
      if (promise) {
        await promise;
      } else {
        await queryFn();
      }
      return data.value;
    },
  };
};
