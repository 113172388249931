import { computed, ref, Ref } from 'vue';
import { useModelDialog } from '@/hooks/useModelDialog';
import {
  mapAddressObj,
  StandartizeApi,
} from '@/service/standartizeService';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useFsspSearch } from '@/pages/admin/standartize/main/filters/useFsspSearch';
import { useMirCourtSearch } from '@/pages/admin/standartize/main/filters/useMirCourtSearch';
import { useRegCourtSearch } from '@/pages/admin/standartize/main/filters/useRegCourtSearch';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { addressTree } from '@/pages/admin/standartize/main/addressLevelTree';
import { StandartizeAddress } from '@/service/standartize/types';

type AddrObjPrep = {
  objectid: string;
  name: string;
}

type AddAddressModel = {
  source: string;
  cadnum: string;
  postalcode: string;
  area_fias: AddrObjPrep;
  region_fias: AddrObjPrep;
  city_fias: AddrObjPrep;
  settlement_fias: AddrObjPrep;
  structure_fias: AddrObjPrep;
  street_fias: AddrObjPrep;
  house_fias: AddrObjPrep;
  flat_fias: AddrObjPrep;
  room_fias: AddrObjPrep;
  carplace_fias: AddrObjPrep;
  fssp: { name: string; code: string };
  mir_court: { name: string; id: number };
  reg_court: { name: string; id: number };
  is_verified_fssp: boolean;
  is_verified_mir_court: boolean;
  is_verified_reg_court: boolean;
  is_verified_result: boolean;
  is_manual_editing: boolean;
  // перезапись полей
  override_house: boolean;
  override_flat: boolean;
  override_room: boolean;
  override_carplace: boolean;
  house: string;
  house_build: string;
  house_struct: string;
  flat: string;
  room: string;
  carplace: string;
}

type AddressFields = keyof Omit<
  AddAddressModel,
  'source'|'cadnum'|'fssp'|'mir_court'|'reg_court'|'is_verified_fssp'
  |'is_verified_mir_court'|'is_verified_reg_court'|'is_verified_result'|'is_manual_editing'
>

const fields: (keyof StandartizeAddress)[] = [
  'source',
  'postalcode',
  'cadnum',
  'is_manual_editing',
  // 'is_verified_result',
  'fssp', 'is_verified_fssp',
  'mir_court', 'is_verified_mir_court',
  'reg_court', 'is_verified_reg_court',
  'area_fias', 'region_fias',
  'city_fias', 'settlement_fias', 'structure_fias', 'street_fias', 'house_fias', 'flat_fias',
  // @ts-ignore ??
  'room_fias', 'carplace_fias'];

const addressFields = [
  'region_fias', 'area_fias',
  'city_fias', 'settlement_fias', 'structure_fias', 'street_fias', 'house_fias', 'flat_fias', 'room_fias', 'carplace_fias',
] as Array<AddressFields>;

const addressFieldsReversed = addressFields.reverse();

const apiMethods = {
  house_fias: 'house',
  flat_fias: 'flat',
} as { [key in AddressFields]: 'house'|'flat' };

const getApiMethod = (key: AddressFields): 'flat'|'house'|'addrObj' => apiMethods[key] || 'addrObj';

const getNonNullParent = (
  addressField: AddressFields, model: AddAddressModel,
): AddrObjPrep|null => {
  const i = addressFieldsReversed.findIndex((k) => k === addressField);
  if (i === addressFieldsReversed.length - 1) return null;
  const parentField = addressFieldsReversed[i + 1];
  const value = model[parentField];
  if (!value) return getNonNullParent(addressFieldsReversed[i + 1], model);
  // @ts-ignore
  return value;
};

const mapFieldValueToApi = (field: keyof AddAddressModel, value: any, isManual: boolean) => {
  if (field === 'fssp') return value?.code;
  if (field === 'mir_court') return value?.id;
  if (field === 'reg_court') return value?.id;

  if (addressFields.includes(field as AddressFields)) {
    if (isManual) {
      return value?.objectid ?? null;
    }
    return null;
  }
  if (typeof value === 'string') {
    const result = value.trim();
    if (!result) {
      return null;
    }
    return result;
  }
  return value;
};

export const searchAddr = (
  aoLevels: number[],
  addressLevel: AddressFields,
) => (search: string, model: any) => {
  const parent_id = getNonNullParent(addressLevel, model)?.objectid ?? null;
  return StandartizeApi[getApiMethod(addressLevel)].getList({
    limit: 300,
    page: 1,
    filters: {
      parent_id,
      aolevel: aoLevels.join(','),
      name: search,
    },
  }).then((r) => {
    if (!r.status) return [];
    return r.response.results.map((value) => value);
  });
};

export const useAddAddressDialog = (
  regionCode: Ref<string>,
  records: Ref<StandartizeAddress[]>,
) => {
  const { showToast } = useToast();
  const search = searchAddr;

  const fsspSearch = useFsspSearch(regionCode);
  const mirCourtSearch = useMirCourtSearch(regionCode);
  const regCourtSearch = useRegCourtSearch(regionCode);

  const currentEditingItemSlug = ref<string>();
  const getNextEditingItemSlug = computed(
    () => {
      const i = records.value.findIndex(
        (el) => el.slug === currentEditingItemSlug.value,
      );
      if (i !== -1) {
        const slug = records.value[i + 1]?.slug;
        if (slug) return () => slug;
      }
      return () => undefined;
    },
  );
  const getPrevEditingItemSlug = computed(
    () => {
      const i = records.value.findIndex(
        (el) => el.slug === currentEditingItemSlug.value,
      );
      if (i !== -1) {
        const slug = records.value[i - 1]?.slug;
        if (slug) return () => slug;
      }
      return () => undefined;
    },
  );

  const standartizeDialog = useModelDialog<
    AddAddressModel,
    AddAddressModel,
    string
  >({
    getPrevInitPayload: getPrevEditingItemSlug,
    getNextInitPayload: getNextEditingItemSlug,
    saveOnNavigate: true,
    onNavigate: (slug: string) => {
      currentEditingItemSlug.value = slug;
    },
    getForm: (slug) => StandartizeApi.address.getItem(
      slug,
    ).then(
      async (res) => {
        if (!res.status) {
          await showToast({
            label: 'Запись не найдена',
            level: IToastLevel.danger,
          });
          return {
            status: false,
            response: null,
          };
        }
        const response = res.response;

        const result = fields.reduce((acc, field) => {
          // @ts-ignore
          const value = response[field];
          if (addressFields.includes(field as any)) {
            // @ts-ignore
            acc[field] = value ? mapAddressObj(value) : value;
          } else {
            // @ts-ignore
            acc[field] = response[field];
          }
          // eslint-disable-next-line guard-for-in, no-restricted-syntax
          for (const f in response.overrided_fields) {
            // @ts-ignore
            acc[f] = response.overrided_fields[f];
            const keyStart = f.split('_')[0] as 'house' | 'flat' | 'room';
            acc[`override_${keyStart}`] = true;
          }
          acc.cadnum = response.main_cadnum?.cadnum;
          return acc;

        }, {} as unknown as AddAddressModel);

        return {
          status: true,
          response: {
            ...result,
            is_manual_editing: !result.is_manual_editing,
          },
        };
      },
    ),
    title: 'Редактирование адреса',
    fields: [
      {
        key: 'source',
        field: 'source',
        type: ActiveFormFieldType.input,
        options: {
          label: 'Сырой вариант',
        },
      },
      {
        key: 'cadnum',
        field: 'cadnum',
        type: ActiveFormFieldType.input,
        options: {
          label: 'Кадастровый номер',
        },
      },
      // {
      //   key: 'is_verified_result',
      //   field: 'is_verified_result',
      //   type: ActiveFormFieldType.checkbox,
      //   options: {
      //     label: 'Адрес корректный',
      //   },
      // },
      {
        key: 'is_manual_editing',
        field: 'is_manual_editing',
        type: ActiveFormFieldType.checkbox,
        options: {
          label: 'Редактировать вручную',
          trueValue: false,
          falseValue: true,
        },
      },
      {
        key: 'postalcode',
        field: 'postalcode',
        type: ActiveFormFieldType.input,
        blockedBy: ['is_manual_editing'],
        options: {
          label: 'Почтовый индекс',
        },
      },
      {
        key: 'region_fias',
        field: 'region_fias',
        type: ActiveFormFieldType.searchSelect,
        onUpdateModelValue: (model, value) => {
          if (value) {
            model.is_verified_result = true;
          }
          addressTree.region_fias.forEach(
            (k) => {
              model[k] = null;
            },
          );
        },
        blockedBy: ['is_manual_editing'],
        options: {
          label: 'Регион',
          valueIsObj: true,
          prefetch: true,
          valueField: 'value',
          displayField: 'name',
          searchQuery: search([1], 'region_fias'),
        },
      },
      {
        key: 'area_fias',
        field: 'area_fias',
        type: ActiveFormFieldType.searchSelect,
        onUpdateModelValue: (model, value) => {
          if (value) {
            model.is_verified_result = true;
          }
          addressTree.area_fias.forEach(
            (k) => {
              model[k] = null;
            },
          );
        },
        blockedBy: ['is_manual_editing'],
        options: {
          label: 'Район',
          valueIsObj: true,
          valueField: 'value',
          displayField: 'name',
          searchQuery: search([2, 3], 'area_fias'),
        },
      },
      {
        key: 'city_fias',
        field: 'city_fias',
        type: ActiveFormFieldType.searchSelect,
        onUpdateModelValue: (model, value) => {
          if (value) {
            model.is_verified_result = true;
          }
          addressTree.city_fias.forEach(
            (k) => {
              model[k] = null;
            },
          );
        },
        blockedBy: ['is_manual_editing'],
        options: {
          label: 'Город',
          valueIsObj: true,
          valueField: 'value',
          displayField: 'name',
          searchQuery: search([4, 5], 'city_fias'),
        },
      },
      {
        key: 'settlement_fias',
        field: 'settlement_fias',
        type: ActiveFormFieldType.searchSelect,
        onUpdateModelValue: (model, value) => {
          if (value) {
            model.is_verified_result = true;
          }
          addressTree.settlement_fias.forEach(
            (k) => {
              model[k] = null;
            },
          );
        },
        blockedBy: ['is_manual_editing'],
        options: {
          label: 'Поселение',
          valueIsObj: true,
          valueField: 'value',
          displayField: 'name',
          searchQuery: search([6], 'settlement_fias'),
        },
      },
      {
        key: 'structure_fias',
        field: 'structure_fias',
        type: ActiveFormFieldType.searchSelect,
        onUpdateModelValue: (model, value) => {
          if (value) {
            model.is_verified_result = true;
          }
          addressTree.structure_fias.forEach(
            (k) => {
              model[k] = null;
            },
          );
        },
        blockedBy: ['is_manual_editing'],
        options: {
          label: 'Планировочная структура',
          valueIsObj: true,
          valueField: 'value',
          displayField: 'name',
          searchQuery: search([7], 'structure_fias'),
        },
      },
      {
        key: 'street_fias',
        field: 'street_fias',
        type: ActiveFormFieldType.searchSelect,
        onUpdateModelValue: (model, value) => {
          if (value) {
            model.is_verified_result = true;
          }
          addressTree.street_fias.forEach(
            (k) => {
              model[k] = null;
            },
          );
        },
        blockedBy: ['is_manual_editing'],
        options: {
          label: 'Улица',
          valueIsObj: true,
          valueField: 'value',
          displayField: 'name',
          searchQuery: search([8], 'street_fias'),
        },
      },
      {
        key: 'override_house',
        field: 'override_house',
        type: ActiveFormFieldType.checkbox,
        onUpdateModelValue: (model, value) => {
          if (value) {
            model.house_fias = null;
            addressTree.house_fias.forEach(
              (k) => {
                model[k] = null;
              },
            );
          }
        },
        blockedBy: ['is_manual_editing'],
        options: {
          label: 'Дома нет в ГАР',
        },
      },
      {
        key: 'house_fias',
        field: 'house_fias',
        type: ActiveFormFieldType.searchSelect,
        onUpdateModelValue: (model, value) => {
          if (value) {
            model.is_verified_result = true;
          }
          addressTree.house_fias.forEach(
            (k) => {
              model[k] = null;
            },
          );
        },
        blockedBy: ['is_manual_editing', 'override_house'],
        checkHidden: (model) => model.override_house,
        options: {
          label: 'Дом',
          valueIsObj: true,
          valueField: 'value',
          displayField: 'name',
          fetchOnFocus: true,
          searchQuery: search([9, 10], 'house_fias'),
        },
      },
      {
        key: 'house',
        field: 'house',
        type: ActiveFormFieldType.input,
        blockedBy: ['is_manual_editing'],
        checkHidden: (model) => !model.override_house,
        options: {
          label: 'Номер дома (ручной)',
        },
      },
      {
        key: 'house_build',
        field: 'house_build',
        type: ActiveFormFieldType.input,
        blockedBy: ['is_manual_editing'],
        checkHidden: (model) => !model.override_house,
        options: {
          label: 'Корпус (ручной)',
        },
      },
      {
        key: 'house_struct',
        field: 'house_struct',
        type: ActiveFormFieldType.input,
        blockedBy: ['is_manual_editing'],
        checkHidden: (model) => !model.override_house,
        options: {
          label: 'Строение (ручной)',
        },
      },
      {
        key: 'override_flat',
        field: 'override_flat',
        type: ActiveFormFieldType.checkbox,
        blockedBy: ['is_manual_editing'],
        onUpdateModelValue: (model, value) => {
          if (value) {
            model.flat_fias = null;
            addressTree.flat_fias.forEach(
              (k) => {
                model[k] = null;
              },
            );
          }
        },
        options: {
          label: 'Квартиры нет в ГАР',
        },
      },
      {
        key: 'flat_fias',
        field: 'flat_fias',
        type: ActiveFormFieldType.searchSelect,
        onUpdateModelValue: (model, value) => {
          if (value) {
            model.is_verified_result = true;
          }
        },
        checkHidden: (model) => model.override_flat,
        blockedBy: ['is_manual_editing', 'override_flat'],
        options: {
          label: 'Квартира',
          valueIsObj: true,
          valueField: 'value',
          displayField: 'name',
          fetchOnFocus: true,
          searchQuery: search([11], 'flat_fias'),
        },
      },
      {
        key: 'flat',
        field: 'flat',
        type: ActiveFormFieldType.input,
        checkHidden: (model) => !model.override_flat,
        options: {
          label: 'Квартира (ручной)',
        },
      },
      {
        key: 'override_room',
        field: 'override_room',
        type: ActiveFormFieldType.checkbox,
        blockedBy: ['is_manual_editing'],
        onUpdateModelValue: (model, value) => {
          if (value) {
            model.room_fias = null;
          }
        },
        options: {
          label: 'Комнаты нет в ГАР',
        },
      },
      {
        key: 'room_fias',
        field: 'room_fias',
        type: ActiveFormFieldType.searchSelect,
        onUpdateModelValue: (model, value) => {
          if (value) {
            model.is_verified_result = true;
          }
        },
        blockedBy: ['is_manual_editing'],
        checkHidden: (model) => model.override_room,
        options: {
          label: 'Комната',
          valueIsObj: true,
          valueField: 'value',
          displayField: 'name',
          fetchOnFocus: true,
          searchQuery: search([12], 'room_fias'),
        },
      },
      {
        key: 'room',
        field: 'room',
        type: ActiveFormFieldType.input,
        checkHidden: (model) => !model.override_room,
        options: {
          label: 'Комната (ручной)',
        },
      },
      {
        key: 'override_carplace',
        field: 'override_carplace',
        type: ActiveFormFieldType.checkbox,
        blockedBy: ['is_manual_editing'],
        onUpdateModelValue: (model, value) => {
          if (value) {
            model.carplace_fias = null;
          }
        },
        options: {
          label: 'Машино-места нет в ГАР',
        },
      },
      {
        key: 'carplace_fias',
        field: 'carplace_fias',
        type: ActiveFormFieldType.searchSelect,
        onUpdateModelValue: (model, value) => {
          if (value) {
            model.is_verified_result = true;
          }
        },
        checkHidden: (model) => model.override_carplace,
        blockedBy: ['is_manual_editing'],
        options: {
          label: 'Машино-место',
          valueIsObj: true,
          valueField: 'value',
          displayField: 'name',
          fetchOnFocus: true,
          searchQuery: search([17], 'carplace_fias'),
        },
      },
      {
        key: 'carplace',
        field: 'carplace',
        type: ActiveFormFieldType.input,
        checkHidden: (model) => !model.override_carplace,
        options: {
          label: 'Машино-место (ручной)',
        },
      },
      {
        key: 'fssp',
        field: 'fssp',
        type: ActiveFormFieldType.searchSelect,
        options: {
          label: 'Участок ФССП',
          valueIsObj: true,
          prefetch: true,
          valueField: 'value',
          displayField: 'name',
          searchQuery: fsspSearch.awaitRecords,
        },
      },
      {
        key: 'is_verified_fssp',
        field: 'is_verified_fssp',
        type: ActiveFormFieldType.checkbox,
        options: {
          label: 'Участок ФССП корректный',
        },
      },
      {
        key: 'mir_court',
        field: 'mir_court',
        type: ActiveFormFieldType.searchSelect,
        options: {
          label: 'Участок мирового судьи',
          valueIsObj: true,
          prefetch: true,
          valueField: 'value',
          displayField: 'name',
          searchQuery: mirCourtSearch.awaitRecords,
        },
      },
      {
        key: 'is_verified_mir_court',
        field: 'is_verified_mir_court',
        type: ActiveFormFieldType.checkbox,
        options: {
          label: 'Участок мирового судьи корректный',
        },
      },
      {
        key: 'reg_court',
        field: 'reg_court',
        type: ActiveFormFieldType.searchSelect,
        options: {
          label: 'Участок районного суда',
          valueIsObj: true,
          prefetch: true,
          valueField: 'value',
          displayField: 'name',
          searchQuery: regCourtSearch.awaitRecords,
        },
      },
      {
        key: 'is_verified_reg_court',
        field: 'is_verified_reg_court',
        type: ActiveFormFieldType.checkbox,
        options: {
          label: 'Участок районного суда корректный',
        },
      },
    ],
    // @ts-ignore
    onModelUpdate: (initModel, slug: string) => {
      const {
        override_house, override_flat, override_room, override_carplace,
        house, house_build, house_struct, flat, room, carplace, ...model
      } = initModel;
      const hasOverridedFields = override_house || override_flat || override_room || override_carplace;
      const overridden = {
        ...(override_house ? {
          house, house_build, house_struct,
        } : {}),
        ...(override_flat ? {
          flat,
        } : {}),
        ...(override_room ? {
          room,
        } : {}),
        ...(override_carplace ? {
          carplace,
        } : {}),
      };
      const isManual = !model.is_manual_editing;

      // const overrided_fields = ['house', 'house_build', 'house_struct', 'flat', 'room']
      // console.log('onModelUpdate', model);
      return StandartizeApi.address.addressUpdateItem({
        slug,
        ...Object.fromEntries(
          Object.entries(model).map(
            ([field, value]) => (field === 'is_manual_editing' ? (['is_manual_editing', isManual]) : ([
              addressFields.includes(field as AddressFields) // key
                ? `${field}_id`
                : field,
              mapFieldValueToApi(field as keyof AddAddressModel, value, isManual), // value
            ])),
          ),
        ),
        ...(isManual ? { is_verified_result: false } : {}),
        ...(isManual && hasOverridedFields ? { overrided_fields: overridden } : {}),
      });
    },
  });

  return {
    showDialog: (slug: string) => {
      currentEditingItemSlug.value = slug;
      return standartizeDialog.showDialog(slug);
    },
  };
};
