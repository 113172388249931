import { StandartizeApi } from '@/service/standartizeService';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { Ref } from 'vue';
import { StandartizeAddress } from '@/service/standartize/types';

export function useOnUpdateRecord(isSaving: Ref<boolean>) {
  const { awaitSignalResponse } = useSignal();
  const { showToast } = useToast();

  const onUpdateRecordValue = async (
    record: StandartizeAddress,
    key: keyof StandartizeAddress,
    value: any,
  ) => {
    if (isSaving.value) {
      return;
    }
    isSaving.value = true;

    if (key !== 'cadnum') {
      // @ts-ignore
      record[key] = value;
      const response = await StandartizeApi.address.updateMany([{
        slug: record.slug,
        [key]: value,
      }]);
      if (response.status) {
        if (key === 'is_verified_cadnum') {
          await awaitSignalResponse(
            SignalType.standartizeUpdated,
            SignalType.standartizeTableRefetched,
          );
        }
        await showToast({
          label: 'Данные обновлены',
          params: {
            pureLabel: 'label',
          },
          level: IToastLevel.success,
        });
      } else {
        await showToast({
          label: 'Ошибка обновления данных',
          params: {
            pureLabel: 'label',
          },
          level: IToastLevel.danger,
        });
      }
    }

    if (key === 'cadnum' && value) {
      const response = await StandartizeApi.cadnum.add({
        cadnum: value,
        slug: record.slug,
        is_default: 1,
      });
      if (response.status) {
        // @ts-ignore
        record.main_cadnum = response.response.main_cadnum;
        await showToast({
          label: 'Данные обновлены',
          params: {
            pureLabel: 'label',
          },
          level: IToastLevel.success,
        });
      }
    }
    isSaving.value = false;
  };

  return { onUpdateRecordValue };
}
