import { getApiRequest } from '@core/service/serviceConstructor';

const request = getApiRequest({
  baseUrl: 'https://api-rr.urrobot.tech:30080/',
});

export const rosreestrService = {
  getStat: () => request<{ count: number; status: string }[]>({
    method: 'GET',
    url: '/stats',
  })(),
};
