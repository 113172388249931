import { Ref } from 'vue';
import { useToast } from '@/hooks/useToast';
import { StandartizeApi } from '@/service/standartizeService';
import { useStandartizeSocket } from '@/hooks/useStandartizeSocket';
import { useTaskProgress } from '@/hooks/useTaskProgress';
import { getRandomString } from '@/utils/string';
import { ProgressTask, StandartizeProgressTask } from '@/store/modules/tasksProgress';
import { StandartizeCadnumLevel } from '@/store/modules/user';

export const useStandartizeSelected = (
  tableRef: Ref<{ resetSelection: () => void } | undefined>,
  cadnumLevels: Ref<StandartizeCadnumLevel[] | undefined>,
) => {
  const { showPureDangerToast } = useToast();
  const { group: socketSubscriberGroup } = useStandartizeSocket();
  const { startTaskProgress } = useTaskProgress();

  const doStandardization = async (slugs: string[], searchCadnums: boolean, force?: boolean) => {
    if (!slugs.length) {
      return;
    }
    const response = await StandartizeApi.address.doSelectedStandartization(
      slugs,
      socketSubscriberGroup.value,
      cadnumLevels.value ?? null,
      searchCadnums ? 1 : undefined,
      force,
    );
    if (!response.status) {
      await showPureDangerToast({
        params: {
          label: 'Ошибка стандартизации',
        },
      });
      return;
    }
    const {
      standartization_progress_uuid,
      save_standartization_progress_uuid,
      cadnum_search_progress_uuid,
    } = response.response;

    await startTaskProgress(({
      key: getRandomString(),
      label: 'Стандартизация выбранных адресов...',
      action: 'startStandartizeTask',
      standartizeTaskUUID: standartization_progress_uuid,
      saveStandartizeTaskUUID: save_standartization_progress_uuid,
      ...(searchCadnums ? { cadnumTaskUUID: cadnum_search_progress_uuid } : {}),

    } as StandartizeProgressTask) as unknown as ProgressTask);

    tableRef.value?.resetSelection();
  };

  return doStandardization;
};
