import {
  StandartizeApi,
} from '@/service/standartizeService';
import { RegionCodeRef } from '@/pages/admin/standartize/main/filters/types';
import { useSearchCommon } from '@/pages/admin/standartize/main/filters/useSearchCommon';
import { StandartizeCourtEntry } from '@/service/standartize/types';

export const useRegCourtSearch = (regionCode:RegionCodeRef) => {

  return useSearchCommon<StandartizeCourtEntry>('reg-courts', regionCode, StandartizeApi.regCourt.getList);

};
