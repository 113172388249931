import { ref } from 'vue';
import { StandartizeApi } from '@/service/standartizeService';
import { ChangeCourtModel } from '@/pages/admin/standartize/main/changedCourt/ChangedCourt.vue';
import { SignalType, useSignal } from '@/hooks/useSignal';

export const useChangeCourtPopover = () => {
  const { awaitSignalResponse } = useSignal();
  const popoverVirtualRef = ref<HTMLElement>();
  const popoverRef = ref<{ hide:() => void }>();
  const popoverIsVisible = ref(false);

  const slug = ref<string>();
  const houseFias = ref<number>();
  const oldMirCourt = ref<number|null>();
  const oldRegCourt = ref<number|null>();
  const newMirCourt = ref<number|null>();
  const newRegCourt = ref<number|null>();

  const showChangeCourtPopover = async (
    addressSlug: string,
    target: HTMLElement,
  ) => {
    slug.value = addressSlug;
    popoverVirtualRef.value = target;

    const addressResponse = await StandartizeApi.address.getItem(addressSlug);

    if (!addressResponse.status) {
      return;
    }

    houseFias.value = +addressResponse.response.house_fias?.objectid;
    oldMirCourt.value = (addressResponse.response.mir_court as any)?.id;
    oldRegCourt.value = (addressResponse.response.reg_court as any)?.id;
    newMirCourt.value = addressResponse.response.changed_mir_court?.court ?? null;
    newRegCourt.value = addressResponse.response.changed_reg_court?.court ?? null;

    popoverIsVisible.value = true;
  };

  const onSubmitChangeCourtForm = async (payload: ChangeCourtModel) => {
    popoverIsVisible.value = false;
    const { mir, reg } = payload;
    console.log('onSubmitChangeCourtForm mir', payload.mir, oldMirCourt.value);
    console.log('onSubmitChangeCourtForm reg', payload.reg, oldRegCourt.value);
    // if (payload.mir && oldMirCourt.value !== payload.mir) {
    //   mir = payload.mir;
    // }
    // if (payload.reg && oldRegCourt.value !== payload.reg) {
    //   reg = payload.reg;
    // }
    if (mir || reg) {
      await StandartizeApi.address.updateManyByPattern({
        house_fias: houseFias.value as number,
        ...(
          mir
            ? {
              mir_court: mir,
              changed_mir_court: null,
            }
            : {}
        ),
        ...(
          reg
            ? {
              reg_court: reg,
              changed_reg_court: null,
            }
            : {

            }
        ),
      });
      await awaitSignalResponse(
        SignalType.standartizeUpdated,
        SignalType.standartizeTableRefetched,
      );
    }
    slug.value = undefined;
    oldRegCourt.value = undefined;
    newRegCourt.value = undefined;
    newMirCourt.value = undefined;
    oldMirCourt.value = undefined;
  };

  return {
    changeCourtPopover: popoverRef,
    changeCourtPopoverVirtualRef: popoverVirtualRef,
    changeCourtPopoverIsVisible: popoverIsVisible,
    oldMirCourt,
    oldRegCourt,
    newMirCourt,
    newRegCourt,
    showChangeCourtPopover,
    onSubmitChangeCourtForm,
  };
};
