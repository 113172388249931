export const cadnumSourceOptions = [
  {
    label: '11 - Ручной ввод',
    value: 11,
  }, {
    label: '21 - Росреестр парсинг кадастровых через Elastix',
    value: 21,
  }, {
    label: '22 - ГАР',
    value: 22,
  }, {
    label: '23 - Росреестр ФИР lite',
    value: 23,
  }, {
    label: '24 - Росреестр ФИР',
    value: 24,
  }, {
    label: '25 - ФИР Lite API',
    value: 25,
  }, {
    label: '27 - ГИС ЖКХ, Реформа ХКЖ, адресный реестр Москвы',
    value: 27,
  }, {
    label: '31 - Федеральная Кадастровая палата',
    value: 31,
  }, {
    label: '32 - ЛК Росреестр',
    value: 32,
  },
];
