import { useRegions } from '@/pages/admin/courts/main/useRegions';
import {
  ApiCommand,
  standartizeRequest,
} from '@/service/standartizeService';
import { useModelDialog } from '@/hooks/useModelDialog';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useToast } from '@/hooks/useToast';
import { AddrObj, StandartizationResponse } from '@/service/standartize/types';

type ChangeRegion = {
  region: AddrObj;
}

export function useChangeRegion() {

  const { showPureDangerToast, showPureSuccessToast } = useToast();
  const { regions } = useRegions();
  let slugs: string[] = [];

  const changeRegion = (slugs: string[], region: number) => standartizeRequest<StandartizationResponse>({
    command: ApiCommand.standartizeSelected,
    data: slugs,
    params: {
      force_region: region,
    },
  });

  const { showDialog } = useModelDialog<
    ChangeRegion,
    ChangeRegion
    >({
      title: 'Смена региона',
      // @ts-ignore regions
      fields: [{
        key: 'region',
        field: 'region',
        type: ActiveFormFieldType.select,
        options: {
          label: 'Регион',
          options: regions,
          displayField: 'full_name',
          valueField: 'objectid',
          isSearchable: true,
          searchLocal: true,
        },
      }],
      // @ts-ignore
      onModelUpdate: async (payload) => {
        const region = payload.region as unknown as number; // yes great typing, getting valueField here not an option object
        const result = await changeRegion(slugs, region);
        if (!result.status) {
          await showPureDangerToast({
            params: { label: 'Ошибка при обработке запроса' },
          });
          return result;
        }
        await showPureSuccessToast({
          params: { label: 'Запрос успешно отправлен' },
        });
        return result;
      },
    });

  async function updateRegion(slugs_: string[]) {
    console.warn('update', slugs_, regions.value);
    slugs = slugs_;
    await showDialog();
  }
  return { updateRegion };
}
