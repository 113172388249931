import { ActionType, useActiveTable } from '@/components/activeTable/useActiveTable';
import { computed, ref, Ref } from 'vue';
import {
  StandartizeApi,
} from '@/service/standartizeService';
import { CourtType, JurisdictionModelClient } from '@/service/standartize/jurisdiction-types';

export const useCourtTable = (
  isSaving: Ref<boolean>,
  activeTab: Ref<CourtType>,
) => {
  const table = useActiveTable<
    JurisdictionModelClient & { court_type: CourtType },
    JurisdictionModelClient,
    'id'
    >({
      keyField: 'id',
      name: 'courts',
      // @ts-ignore
      filters: computed(() => ([
        {
          key: 'court_id',
          field: 'court_id',
          isVisible: false,
          // defaultValue: courtId.value,
        },
        {
          key: 'court_type',
          field: 'court_type',
          isVisible: false,
          defaultValue: 'mir_court',
        },
      ])),
      mapFiltersModelToRequest: (filtersModel) => ({
        court_id: filtersModel.court_id,
        court_type: filtersModel.court_type,
      }),
      // @ts-ignore
      async fetch({ params }) {
        // const { court_type } = params?.filters || {};
        if (!params.filters?.court_id) {
          return {
            total: 0,
            page: 1,
            results: [],
          };
        }
        // @ts-ignore
        const response = await StandartizeApi.jurisdiction.getList({
          ...params,
          court_type: activeTab.value,
        });
        return response.response;
      },
      actions: computed(() => ([
        {
          key: 'remove',
          icon: 'trash',
          types: [ActionType.record],
          handler: async ({ selectedItems: [id] }) => {
            // @ts-ignore
            if (id === 'new') {
              const i = table.records.value.findIndex((v) => v.id === id);
              if (i !== -1) {
                table.records.value.splice(i, 1);
              }
              return;
            }
            isSaving.value = true;
            const { status } = await StandartizeApi.jurisdiction.deleteItem({
              court_type: activeTab.value,
              court_id: table.filtersModel.value.court_id,
              id,
            });
            if (status) {
              const i = table.records.value.findIndex((v) => v.id === id);
              if (i !== -1) {
                table.records.value.splice(i, 1);
              }
            }
            isSaving.value = false;
          },
        },
      ])),
      columns: computed(() => ([
        {
          key: 'addr_obj',
          field: 'addr_obj',
          label: 'Улица',
        },
        {
          key: 'houses_include_all',
          field: 'houses_include_all',
          label: 'Все',
          width: 'minmax(100px, 100px)',
        },
        {
          key: 'houses_include',
          field: 'houses_include',
          label: 'Включить дома',
        },
        {
          key: 'houses_exclude',
          field: 'houses_exclude',
          label: 'Исключить дома',
        },
        {
          key: 'houses_include_even_all',
          field: 'houses_include_even_all',
          label: 'Все четн.',
          width: 'minmax(100px, 100px)',
        },
        {
          key: 'houses_even',
          field: 'houses_even',
          label: 'Включить четные',
        },
        {
          key: 'houses_include_odd_all',
          field: 'houses_include_odd_all',
          label: 'Все нечетн.',
          width: '100px',
        },
        {
          key: 'houses_odd',
          field: 'houses_odd',
          label: 'Включить нечетные',
        },
      ])),
      defaultLimit: ref(50),
    });

  return table;
};
