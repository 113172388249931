import { useFsspSearch } from '@/pages/admin/standartize/main/filters/useFsspSearch';
import { useMirCourtSearch } from '@/pages/admin/standartize/main/filters/useMirCourtSearch';
import { useRegCourtSearch } from '@/pages/admin/standartize/main/filters/useRegCourtSearch';
import { RegionCodeRef } from '@/pages/admin/standartize/main/filters/types';

export function useSearch(regionCode: RegionCodeRef) {

  const { records: fssp } = useFsspSearch(regionCode);
  const { awaitRecords: searchMirCourt, records: mirCourts } = useMirCourtSearch(regionCode);
  const { awaitRecords: searchRegCourt, records: regCourts } = useRegCourtSearch(regionCode);

  return {
    fssp,
    mirCourts,
    regCourts,
    searchMirCourt,
    searchRegCourt,
  };
}
