import { StandartizeApi } from '@/service/standartizeService';
import {
  mapStandartizeFiltersToQuery,
} from '@/pages/admin/standartize/main/mapStandartizeFiltersToQuery';
import { StandartizeAutomatitionSettings } from '@/hooks/useUserPrefs';
import { Ref } from 'vue';
import { useStandartizeSocket } from '@/hooks/useStandartizeSocket';
import { getRandomString } from '@/utils/string';
import { ProgressTask, StandartizeProgressTask } from '@/store/modules/tasksProgress';
import { useTaskProgress } from '@/hooks/useTaskProgress';
import { AddressTableFull } from '@/pages/admin/standartize/main/useAddressTable';
import { StandartizeCadnumLevel } from '@/store/modules/user';
import { useAutomation } from '@/pages/admin/standartize/main/commands/useAutomation';
import { useToast } from '@/hooks/useToast';
import {
  useStandartizeSelected,
} from '@/pages/admin/standartize/main/commands/useStandartizeSelected';
import { ApiResponse } from '@/service/api';
import { useChangeRegion } from '@/pages/admin/standartize/main/commands/useChangeRegion';
import { StandartizationResponse } from '@/service/standartize/types';

type Command =
  'file'
  | 'file-cadnum'
  | 'selected'
  | 'selected-cadnum'
  | 'force'
  | 'split'
  | 'update-linked-slugs'
  | 'change-region'

type Args = {
  addressTable: AddressTableFull;
  cadnumLevels: Ref<StandartizeCadnumLevel[] | undefined>;
  selection: Ref<string[]>;
  tableRef: Ref<{ resetSelection:() => void } | undefined>;
}

export function useMainCommands({
  addressTable,
  cadnumLevels,
  selection,
  tableRef,
}: Args) {

  const { startTaskProgress } = useTaskProgress();
  const { group: socketSubscriberGroup } = useStandartizeSocket();
  const { showToast } = useToast();
  const { updateRegion } = useChangeRegion();

  const doSelectedStandartization = useStandartizeSelected(
    tableRef,
    cadnumLevels,
  );

  async function handleStandartizeCommand(command: Command) {
    if (command === 'file') {
      await doStandartization(false, false);
    } else if (command === 'file-cadnum') {
      await doStandartization(true, false);
    } else if (command === 'selected') {
      await doSelectedStandartization(selection.value, false);
    } else if (command === 'force') {
      await doSelectedStandartization(selection.value, false, true);
    } else if (command === 'split') {
      await splitSourceAddresses();
    } else if (command === 'change-region') {
      await updateRegion(selection.value);
    } else if (command === 'update-linked-slugs') {
      await updateLinkedAddressSlugs();
    } else {
      await doSelectedStandartization(selection.value, true);
    }
  }

  const [automationSettings, updateAutomationSettings] = useAutomation(
    doAutoStandartization,
  );

  const startProgress = async ({ response }: { response: StandartizationResponse }, searchCadnums: boolean) => {

    await startStandardizeProgress({
      standartizeTaskUUID: response.standartization_progress_uuid,
      saveStandartizeTaskUUID: response.save_standartization_progress_uuid,
      ...(searchCadnums ? { cadnumTaskUUID: response.cadnum_search_progress_uuid } : {}),
    });
  };

  const startStandardizeProgress = (
    { standartizeTaskUUID, saveStandartizeTaskUUID, cadnumTaskUUID }: { standartizeTaskUUID: string; saveStandartizeTaskUUID?: string; cadnumTaskUUID?: string },
  ) => {
    return startTaskProgress(({
      key: getRandomString(),
      standartizeTaskUUID,
      cadnumTaskUUID,
      saveStandartizeTaskUUID,
      action: 'startStandartizeTask',
    } as StandartizeProgressTask) as unknown as ProgressTask);
  };

  async function doStandartization(
    searchCadnums: boolean,
    withFilters: boolean,
    force?: boolean,
  ) {
    const response = await StandartizeApi.address.doStandartization({
      limit: addressTable.total.value,
      page: 1,
      filters: {
        standardization_file: addressTable.filtersModel.value.standardization_file,
        ws_group: socketSubscriberGroup.value,
        ...(cadnumLevels.value ? { allow_levels: cadnumLevels.value.join(',') } : {}),
        ...(
          searchCadnums ? { search_cadnums: true } : { search_cadnums: false }
        ),
        // @ts-ignore
        ...(withFilters ? mapStandartizeFiltersToQuery(addressTable.filtersModel.value) : {}),
        ...(force ? { ignore_saved: true } : {}),
      },
    });
    if (response.status) {
      await startProgress(response, searchCadnums);
    }
  }

  async function doAutoStandartization(
    config: StandartizeAutomatitionSettings,
    withFilters?: boolean,
  ) {
    if (!config.enabled) {
      return;
    }
    if (config.type === 'file') {
      await doStandartization(config.withCadnums, withFilters ?? false, config.force);

    } else if (config.type === 'page') {
      const response = await StandartizeApi.address.doStandartization({
        page: addressTable.page.value,
        limit: addressTable.limit.value,
        filters: {
          ws_group: socketSubscriberGroup.value,
          ...(cadnumLevels.value ? { allow_levels: cadnumLevels.value.join(',') } : {}),
          // @ts-ignore
          ...mapStandartizeFiltersToQuery(addressTable.filtersModel.value),
          ...(
            config.force ? { ignore_saved: true } : { }
          ),
        },
      });
      if (response.status) {
        await startProgress(response, config.withCadnums);
      }
    } else {
      const offset = (config.pageFrom - 1) * addressTable.limit.value;
      const limit = config.pageTo * addressTable.limit.value - offset;
      const response = await StandartizeApi.address.doStandartization({
        page: 1,
        offset,
        limit,
        filters: {
          ws_group: socketSubscriberGroup.value,
          // @ts-ignore
          ...mapStandartizeFiltersToQuery(addressTable.filtersModel.value),
          ...(
            config.withCadnums ? { search_cadnums: true } : { search_cadnums: false }
          ),
          ...(cadnumLevels.value ? { allow_levels: cadnumLevels.value.join(',') } : {}),
          ...(
            config.force ? { ignore_saved: true } : { }
          ),
        },
      });
      if (response.status) {
        await startProgress(response, config.withCadnums);
      }
    }
  }

  function splitSourceAddresses() {
    return updateLinked(StandartizeApi.address.updateLinkedAddressStandartization);
  }

  function updateLinkedAddressSlugs() {
    return updateLinked(StandartizeApi.address.updateLinkedAddressSlugs);
  }

  const updateLinked = async (command: (model: string[]) => Promise<ApiResponse<StandartizationResponse>>) => {
    const selectedAddresses = selection.value;
    const slugsToStandartize = addressTable.records.value.filter(
      (address) => selectedAddresses.includes(address.slug) && address.linked_addresses?.length,
    ).reduce((acc, address) => {
      acc.push(...address.linked_addresses.map(({ slug }) => slug));
      return acc;
    }, [] as string[]);
    if (!slugsToStandartize.length) {
      await showToast({
        label: 'Ни один адрес не выбран',
      });
      return;
    }
    // т.к. адреса могут разделиться после стандартизации - надо перезапросить таблицу с фильтрами
    addressTable.keepUpdatedAddresses.value = false;
    const response = await command(slugsToStandartize);

    if (!response.status) {
      console.error(response.response);
      return;
    }

    await startStandardizeProgress({
      standartizeTaskUUID: response.response.standartization_progress_uuid,
      saveStandartizeTaskUUID: response.response.save_standartization_progress_uuid,
    });
  };

  return {
    automationSettings,
    updateAutomationSettings,
    doAutoStandartization,
    doSelectedStandartization,
    handleStandartizeCommand,
  };
}
