import {
  StandartizeApi,

} from '@/service/standartizeService';
import { RegionCodeRef } from '@/pages/admin/standartize/main/filters/types';
import { useSearchCommon } from '@/pages/admin/standartize/main/filters/useSearchCommon';
import { StandartizeFsspEntry } from '@/service/standartize/types';

export const useFsspSearch = (regionCode: RegionCodeRef) => {

  return useSearchCommon<StandartizeFsspEntry>('fssp', regionCode, StandartizeApi.fssp.getList);

};
