import { IToastLevel, useToast } from '@/hooks/useToast';
import { rosreestrService } from '@core/service/rosreestrService';

export const useRosreestrStats = () => {
  const { showToast } = useToast();

  return async () => {
    const closeToast = await showToast({
      label: 'Загрузка..',
      level: IToastLevel.info,
      duration: null,
    });
    const response = await rosreestrService.getStat();
    closeToast();
    if (response._tag === 'Left') {
      showToast({
        label: 'Ошибка получаения статистики',
        level: IToastLevel.danger,
        duration: 5000,
      });
    } else {
      showToast({
        label: 'Статистика росреестра',
        message: response.right.map(({ count, status }) => `${status}: ${count}`).join('\n'),
        level: IToastLevel.info,
        duration: 8000,
        isCloseable: true,
      });
    }
  };
};
