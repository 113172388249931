<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <div :class="$style.title">
        Коды кад. номеров:
      </div>
      11 - Ручной ввод
      <br>
      21 - Росреестр парсинг кадастровых через Elastix
      <br>
      <a
        :class="$style.link"
        href="https://fias.nalog.ru/"
        target="_blank"
      >22 - ГАР</a>
      <a
        :class="$style.link"
        href="https://rosreestr.gov.ru/fir_lite_rest/api/gkn/address/fir_objects"
        target="_blank"
      >23 - Росреестр ФИР lite</a>
      <br>
      <a
        :class="$style.link"
        href="https://rosreestr.gov.ru/fir_rest/api/fir/address/fir_objects"
        target="_blank"
      >24 - Росреестр ФИР</a>
      <br>
      25 - ФИР Lite API
      <br>
      27 - ГИС ЖКХ, Реформа ХКЖ, адресный реестр Москвы
      <br>
      <a
        :class="$style.link"
        href="https://spv.kadastr.ru/"
        target="_blank"
      >31 - Федеральная Кадастровая палата</a>
      <br>
      <a
        :class="$style.link"
        href="https://lk.rosreestr.ru/eservices/real-estate-objects-online"
        target="_blank"
      >32 - ЛК Росреестр</a>
    </div>
  </div>
</template>

<style lang="scss" module>

@import "~@/assets/utils";

.container {
  height: rem(100px);
  text-align: right;
  padding-right: rem(20px);
  padding-top: rem(10px);
  color: rgba(var(--gray-2), 1);
}

.title {
  @include subText();
  display: inline-block;
}

.content {
  @include small();
}

.link {
  margin: rem(20px) auto 0;
  color: rgba(var(--btn-color), 1);
  text-decoration: underline;
  cursor: pointer;
}
</style>
