import { useModelDialog } from '@/hooks/useModelDialog';
import { StandartizeApi } from '@/service/standartizeService';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useStore } from 'vuex';

export const useStandartizeAuthDialog = () => {
  const store = useStore();

  const modelDialog = useModelDialog<
    { username: string; password: string },
    { username: string; password: string }
    >({
      onModelUpdate: (model) => StandartizeApi.auth.logIn(model).then((response) => {
        if (!response.status) {
          return response;
        }
        store.commit('user/setStandartizeToken', { token: response.response.key });
        return {
          status: true,
          response: model,
        };
      }),
      title: 'Введите логин/пароль сервера стандартизации',
      fields: [
        {
          key: 'username',
          field: 'username',
          type: ActiveFormFieldType.input,
          options: {
            label: 'Имя пользователя',
          },
        },
        {
          key: 'password',
          field: 'password',
          type: ActiveFormFieldType.input,
          options: {
            label: 'Пароль',
          },
        },
      ],
    });

  return modelDialog;
};
