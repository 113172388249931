import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { StandartizeCadnumLevel } from '@/store/modules/user';

export const useStandartizeCadnumLevels = (
  onUpdateCadnumLevels: () => Promise<void>,
) => {
  const store = useStore();
  const cadnumLevelsPopoverRef = ref<{ hide:() => void}|undefined>();

  const cadnumLevels = computed(() => {
    return store.state.user.standartizeCadnumLevels as StandartizeCadnumLevel[] | undefined;
  });

  const updateCadnumLevels = async (levels: StandartizeCadnumLevel[] | null) => {
    cadnumLevelsPopoverRef.value?.hide();
    store.commit('user/setStandartizeCadnumLevels', levels);
    await onUpdateCadnumLevels();
  };

  return {
    cadnumLevels,
    updateCadnumLevels,
    cadnumLevelsPopoverRef,
  };
};
