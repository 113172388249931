/* eslint-disable no-mixed-spaces-and-tabs,no-tabs */
import { ref } from 'vue';
import { StandartizeAutomatitionSettings } from '@/hooks/useUserPrefs';
import { useStandartizeSocket } from '@/hooks/useStandartizeSocket';
import { useTaskProgress } from '@/hooks/useTaskProgress';
import { StandartizeApi } from '@/service/standartizeService';
import { useAddressTable } from '@/pages/admin/standartize/main/useAddressTable';
import { getRandomString } from '@/utils/string';
import { ProgressTask, StandartizeProgressTask } from '@/store/modules/tasksProgress';
import { mapStandartizeFiltersToQuery } from '@/pages/admin/standartize/main/mapStandartizeFiltersToQuery';

export const useDoSearchCadnum = (activeAddressTable: ReturnType<typeof useAddressTable>) => {
  const doSearchCadnumPopoverRef = ref<{ hide:() => void }|undefined>();

  const { group: socketSubscriberGroup } = useStandartizeSocket();
  const { startTaskProgress, progressTasks } = useTaskProgress();

  const startStandardizeCadnumProgressTask = (
    { cadnumTaskUUID }: { cadnumTaskUUID?: string },
  ) => startTaskProgress(({
    label: 'Поиск кадастровых',
    key: getRandomString(),
    cadnumTaskUUID,
    action: 'startStandartizeTask',
  } as StandartizeProgressTask) as unknown as ProgressTask);
  const doSearchCadnum = async (config: StandartizeAutomatitionSettings) => {
    // @ts-ignore
    const filters = mapStandartizeFiltersToQuery(activeAddressTable.filtersModel.value);
    console.log('filters999', filters);
		doSearchCadnumPopoverRef.value?.hide();
		if (config.type === 'file') {
		  const response = await StandartizeApi.address.doSearchCadnum({
		    limit: activeAddressTable.total.value,
		    page: 1,
		    filters: {
		      ws_group: socketSubscriberGroup.value,
		      // @ts-ignore
		      ...mapStandartizeFiltersToQuery(activeAddressTable.filtersModel.value),
		      standardization_file: activeAddressTable.filtersModel.value.standardization_file,
		    },
		  });
		  if (!response.status) {
		    return;
		  }

		  const { cadnum_search_progress_uuid } = response.response;

		  startStandardizeCadnumProgressTask({
		    cadnumTaskUUID: cadnum_search_progress_uuid,
		  });
		} else if (config.type === 'page') {
		  const response = await StandartizeApi.address.doSearchCadnum({
		    page: activeAddressTable.page.value,
		    limit: activeAddressTable.limit.value,
		    filters: {
		      ws_group: socketSubscriberGroup.value,
		      // @ts-ignore
		      ...mapStandartizeFiltersToQuery(activeAddressTable.filtersModel.value),
		    },
		  });
		  if (!response.status) {
		    return;
		  }

		  const { cadnum_search_progress_uuid } = response.response;

		  startStandardizeCadnumProgressTask({
		    cadnumTaskUUID: cadnum_search_progress_uuid,
		  });
		} else if (config.type === 'range') {
		  const offset = (config.pageFrom - 1) * activeAddressTable.limit.value;
		  const limit = config.pageTo * activeAddressTable.limit.value - offset;
		  const response = await StandartizeApi.address.doSearchCadnum({
		    page: 1,
		    offset,
		    limit,
		    filters: {
		      ws_group: socketSubscriberGroup.value,
		      // @ts-ignore
		      ...mapStandartizeFiltersToQuery(activeAddressTable.filtersModel.value),
		    },
		  });
		  if (!response.status) {
		    return;
		  }

		  const { cadnum_search_progress_uuid } = response.response;

		  startStandardizeCadnumProgressTask({
		    cadnumTaskUUID: cadnum_search_progress_uuid,
		  });
		}
  };

  return {
    doSearchCadnum, doSearchCadnumPopoverRef,
  };
};
