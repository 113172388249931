import { useStore } from 'vuex';
import { SocketMsg } from '@/types/socket';
import { SocketSubscriber } from '@/store/modules/socket';
import { computed } from 'vue';

export const useStandartizeSocket = () => {
  const store = useStore();

  const group = computed(
    () => store.state.socket.standartizeSubsciberGroup,
  );

  const subscribe = async <T = SocketMsg>(
    config: Omit<SocketSubscriber<T>, 'id'>,
  ): Promise<() => void> => store.dispatch('socket/subscribeStandartize', config);
  const unsubscribeById = async (id: SocketSubscriber['id']) => store.dispatch('socket/unsubscribeStandartize', id);

  return {
    group,
    subscribe,
    unsubscribeById,
  };
};
