import {
  computed, h, onBeforeUnmount, ref, watch,
} from 'vue';
import { wait } from '@/utils/common';
import { useActiveTable } from '@/components/activeTable/useActiveTable';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { useDialog } from '@/hooks/useDialog';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { useUser } from '@/hooks/useUser';
import { useAddressTable } from '@/pages/admin/standartize/main/useAddressTable';
import {
  useStandartizeCadnumLevels,
} from '@/pages/admin/standartize/main/useStandartizeCadnumLevels';
import { useMainCommands } from '@/pages/admin/standartize/main/commands/commands';
import { useStats } from '@/pages/admin/standartize/main/useStats';
import { StandartizeApi } from '@/service/standartizeService';
import { useOnUpdateRecord } from '@/pages/admin/standartize/main/onUpdateRecord';
import { useAddAddressDialog } from '@/pages/admin/standartize/main/useAddAddressDialog';
import { useFileUploadDialog } from '@/pages/admin/standartize/main/useFileUploadDialog';
import {
  StandartizeAddress,
  StandartizeAddressCadnumSuggest,
  StandartizeAddressColor,
} from '@/service/standartize/types';
import { useCadnumSearch } from '@/pages/admin/standartize/main/useCadnumSearch';
import { useRosreestrStats } from '@/pages/admin/standartize/main/useRosreestrStats';
import { useResetCadnumSearch } from '@/pages/admin/standartize/main/useResetCadnumSearch';
import { isStandartizeAdmin } from '@/pages/admin/standartize/main/utils';
import { useIsVerified } from '@/pages/admin/standartize/main/useIsVerified';
import { useSearch } from '@/pages/admin/standartize/main/filters/useSearch';
import { useChangeCourtPopover } from '@/pages/admin/standartize/main/useChangeCourtPopover';

export function useStandartize() {
  const { user } = useUser();
  const { subscribeToSignal, awaitSignalResponse } = useSignal();
  const { confirmDialog } = useDialog();
  const { showToast } = useToast();

  const tableRef = ref<{ resetSelection:() => void }>();
  const addressTable = useAddressTable();

  const selection = ref<string[]>([]);
  const hasSelection = computed(() => selection.value?.length);
  const updateSelection = (values: string[]) => {
    selection.value = values;
  };

  const {
    cadnumLevels,
    updateCadnumLevels,
    cadnumLevelsPopoverRef,

  } = useStandartizeCadnumLevels(
    addressTable.fetchData,
  );

  const { doSelectedStandartization } = useMainCommands({
    addressTable, cadnumLevels, selection, tableRef,
  });
  useStats();

  const activeFile = useActiveTable<any, any, 'id'>({
    keyField: 'id',
    defaultLimit: ref(1000),
    fetch: async ({ params, signal }) => {
      const { response } = await StandartizeApi.file.getList(params);
      return {
        ...response,
        results: (response.results as any[]).sort((a, b) => (+a.region > +b.region ? 1 : -1)),
      };
    },
  });

  const fileRegionCode = computed(() => {
    const files = activeFile.records.value;
    if (!files || !addressTable.filtersModel.value.standardization_file) return null;
    return files.find((f) => f.id === addressTable.filtersModel.value.standardization_file)?.region;
  });

  const currentEditingRowIndex = ref<number>();

  const recordFieldsToUpdate = ref<Record<string, string[]>>({});
  const canUpdate = computed(
    () => Object.entries(recordFieldsToUpdate.value).some(([k, values]) => values.length),
  );

  watch(addressTable.records, () => {
    recordFieldsToUpdate.value = {};
  });

  const isSaving = ref(false);
  const { onUpdateRecordValue } = useOnUpdateRecord(isSaving);

  const { showDialog: showStandartizeDialog } = useAddAddressDialog(
    fileRegionCode,
    addressTable.records,
  );

  const fileUploadDialog = useFileUploadDialog();
  const onFileUpload = async () => {
    const result = await fileUploadDialog.showDialog();
    if (result.status) {
      await showToast({
        label: 'Загрузка данных...',
        params: {
          pureLabel: 'label',
        },
        level: IToastLevel.info,
      });
      activeFile.records.value.push(result.response);
      // ждем 3 секунды перед сменой айди файла (триггер загрузки адресов),
      // т.к. адреса в БД появляются с задержкой
      await wait(3000);
      addressTable.filtersModel.value.standardization_file = result.response.id;
    }
  };

  const onShowStandardizeDialog = async (slug: string) => {
    const result = await showStandartizeDialog(slug);
    if (result.status) {
      await doSelectedStandartization([slug], false, false);
    }
  };

  const deleteFile = async ({ id }: { id: number }, e: any) => {
    e.stopPropagation();
    e.preventDefault();

    const result = await confirmDialog({
      title: 'Удаление файла',
      message: 'Подтвердите удаление',
    });
    result.closeDialog();
    if (!result.result) {
      return;
    }
    const { status, response } = await StandartizeApi.file.deleteFile(id);
    if (!status) {
      return;
    }
    if (addressTable.filtersModel.value.standardization_file === id) {
      addressTable.filtersModel.value.standardization_file = null;
    }
    const index = activeFile.records.value.findIndex((file) => file.id === id);
    if (index === -1) {
      return;
    }
    activeFile.records.value.splice(index, 1);
  };

  const onTogglePageValues = async (
    field: 'is_verified_result' | 'is_verified_fssp' | 'is_verified_mir_court' | 'is_verified_reg_court' | 'is_verified_cadnum',
    value: boolean,
  ) => {
    if (isSaving.value) {
      return;
    }
    isSaving.value = true;
    addressTable.records.value.forEach(
      (address) => {
        address[field] = value;
      },
    );
    const response = await StandartizeApi.address.updateMany(
      addressTable.records.value
        .filter((address) => {
          // при подтверждении всех к.н. на странице
          // исключить те, где к.н. отсутствует
          if (field === 'is_verified_cadnum') {
            if (value) {
              return !!address.main_cadnum;
            }
            return true;
          }
          return true;
        })
        .map((address) => ({
          slug: address.slug,
          [field]: value,
        })),
    );
    if (!response.status) {
      await showToast({
        label: 'Ошибка обновления данных',
        params: {
          pureLabel: 'label',
        },
        level: IToastLevel.danger,
      });
      await awaitSignalResponse(
        SignalType.standartizeUpdated,
        SignalType.standartizeTableRefetched,
      );
    } else {
      if (field === 'is_verified_cadnum') {
        await awaitSignalResponse(
          SignalType.standartizeUpdated,
          SignalType.standartizeTableRefetched,
        );
      }
      await showToast({
        label: 'Успешно обновлено',
        params: {
          pureLabel: 'label',
        },
        level: IToastLevel.success,
      });
    }
    isSaving.value = false;
  };

  const getCadnumSearchQuery = (
    record: StandartizeAddress,
  ) => () => Promise.resolve(record.cadnum_suggest);

  const getCadnumQuality = (opt: StandartizeAddressCadnumSuggest) => {
    if (opt.quality === null || opt.quality === undefined) return '0%';
    return `${opt.quality}%`;
  };

  const getCadnumCodes = (opt: StandartizeAddressCadnumSuggest) => {
    const codes = [...opt.sources];
    if (opt.source && !codes.includes(opt.source)) {
      codes.push(opt.source);
    }
    return codes.filter((code) => ![1, 11, 21].includes(code)).join(', ');
  };

  const renderCadnumOption = (opt: StandartizeAddressCadnumSuggest) => h('div', [
    h('div', [opt.cadnum]),
    h(
      'div',
      { style: { opacity: 0.6 } },
      [
        `Уровень: ${opt.level_name},
              Источник: ${opt.source_name},
              Адрес: ${opt.address},
              Статус: ${opt.status_name},
              Код объекта: ${opt.object_code_name}(${opt.object_code}),
              Код присвоения: ${opt.assignation_code_name}(${opt.assignation_code}),
              Тип присвоения: ${opt.assignation_type_name}(${opt.assignation_type}),
              Исп. по документу: ${opt.util_by_doc},
              Кач-во: ${getCadnumQuality(opt)}
              Коды источников: ${getCadnumCodes(opt)}
              `],
    ),
  ]);

  const renderCadnumInfo = (record: StandartizeAddress) => {
    const found = record.main_cadnum;
    if (!found) {
      return null;
    }
    const quality = getCadnumQuality(found);
    return `${quality}, источники: ${getCadnumCodes(found)}`;
  };

  const getCadnumResults = useCadnumSearch(cadnumLevels);

  const showRosreestrStats = useRosreestrStats();

  const { resetQueueIsVisible, resetQueue } = useResetCadnumSearch();

  const linkedAddresses = computed(() => addressTable.records.value.reduce((acc, record) => {
    if (record.linked_addresses?.length) {
      acc[record.slug] = [...record.linked_addresses];
    }

    return acc;
  }, {} as Record<string, any[]>));

  const expandedRecords = ref<string[]>([]);

  const toggleExpandRecord = (slug: string) => {
    if (expandedRecords.value.includes(slug)) {
      expandedRecords.value = expandedRecords.value.filter(
        (el) => el !== slug,
      );
    } else {
      expandedRecords.value.push(slug);
    }
  };

  const filteredLinkedAddresses = computed(() => {
    return Object.fromEntries(
      Object.entries(linkedAddresses.value)
        .filter(([key, _]) => expandedRecords.value.includes(key)),
    );
  });

  const toggleDuplicateIsVerified = async (duplicate: any) => {
    await StandartizeApi.addressDuplicateUpdateIsVerifiedById(duplicate.id);
    duplicate.is_verified_result = !duplicate.is_verified_result;
  };

  const chosenAddressColor = ref<StandartizeAddressColor>(null);
  const chooseAddressColorPopoverRef = ref<any>();
  const onUpdateChosenColor = (color: StandartizeAddressColor) => {
    if (chooseAddressColorPopoverRef.value) {
      chooseAddressColorPopoverRef.value.hide();
    }
    chosenAddressColor.value = color;
  };

  const onUpdateRecordColor = (record: StandartizeAddress) => {
    onUpdateRecordValue(
      record,
      'color',
      chosenAddressColor.value,
    );
  };

  onBeforeUnmount(subscribeToSignal(
    SignalType.standartizeColorAddressesList,
    (records: StandartizeAddress[]) => {
      if (!records) {
        return;
      }
      records.forEach((rec) => {
        rec.color = chosenAddressColor.value;
      });
      StandartizeApi.address.updateMany(
        records.map(({ slug }) => ({
          slug,
          color: chosenAddressColor.value,
        })),
      ).then((result) => {
        console.log('update result', result);
      });
    },
  ));

  const onDeleteAddress = async (slug: string) => {
    const confirmResult = await confirmDialog({
      title: 'Удалить выбранный адрес?',
      message: 'Подтвердите удаление',
      confirmLabel: 'Удалить',
      cancelLabel: 'Отмена',
    });

    confirmResult.closeDialog();

    if (!confirmResult.result) {
      return;
    }

    const deleteResult = await StandartizeApi.address.deleteItem(slug);

    if (!deleteResult.status) {
      await showToast({
        label: 'Ошибка удаления адреса',
        level: IToastLevel.danger,
      });
      return;
    }
    await showToast({
      label: 'Адрес успешно удален!',
      level: IToastLevel.success,
    });
    const recordIndex = addressTable.records.value.findIndex(
      (record) => record.slug === slug,
    );
    if (recordIndex === -1) {
      console.error(`address index with slug === ${slug} not found`);
      return;
    }
    addressTable.records.value.splice(recordIndex, 1);
  };

  const deleteButtonIsVisible = computed(
    () => isStandartizeAdmin(user.value!.email as string),
  );

  const deleteLinkedAddress = async (parentSlug: string, slugToDelete: string) => {
    const addressRecord = addressTable.records.value.find(
      (addr) => addr.slug === parentSlug,
    );

    if (!addressRecord) {
      return;
    }

    const confirmResult = await confirmDialog({
      title: 'Подтвердите удаление связанного адреса',
      confirmLabel: 'Удалить',
    });
    confirmResult.closeDialog();
    if (!confirmResult.result) {
      return;
    }

    const response = await StandartizeApi.address.deleteLinkedAddresses([slugToDelete]);

    if (!response.status) {
      await showToast({
        label: 'Ошибка удаления адреса',
        level: IToastLevel.danger,
      });
      return;
    }

    const index = addressRecord.linked_addresses.findIndex(
      (addr) => addr.slug === slugToDelete,
    );

    if (index === -1) {
      return;
    }

    addressRecord.linked_addresses.splice(index, 1);
  };

  const isExpandedAllSubrecords = computed(() => {
    const recordsWithLinkedAddresses = addressTable.records.value.filter(
      (record) => record.linked_addresses?.length,
    );
    return recordsWithLinkedAddresses.every(
      (rec) => expandedRecords.value.find((el) => el === rec.slug),
    );
  });

  const toggleExpandAllSubrecords = () => {
    if (isExpandedAllSubrecords.value) {
      expandedRecords.value = [];
    } else {
      const recordsWithLinkedAddresses = addressTable.records.value.filter(
        (record) => record.linked_addresses?.length,
      );
      expandedRecords.value = recordsWithLinkedAddresses.map(({ slug }) => slug);
    }
  };

  return {
    ...useIsVerified(addressTable.records),
    ...useSearch(fileRegionCode),
    ...useChangeCourtPopover(),

    files: activeFile.records,
    awaitFiles: activeFile.awaitRecords,
    onFileUpload,
    addressTable,
    addressRecords: addressTable.records,
    addressColumns: addressTable.columns,
    addressActions: addressTable.actions,
    addressFilters: addressTable.filters,
    addressFiltersModel: addressTable.filtersModel,
    addressPage: addressTable.page,
    addressLimit: addressTable.limit,
    addressTotal: addressTable.total,
    keepUpdatedAddresses: addressTable.keepUpdatedAddresses,
    currentEditingRowIndex,

    onUpdateRecordValue,
    canUpdate,
    onShowStandardizeDialog,

    updateSelection,
    hasSelection,
    deleteFile,
    selection,
    onTogglePageValues,
    tableRef,
    getCadnumSearchQuery,

    fileRegionCode,
    renderCadnumOption,
    getCadnumResults,
    renderCadnumInfo,

    cadnumLevels,
    updateCadnumLevels,
    cadnumLevelsPopoverRef,
    showRosreestrStats,

    resetQueueIsVisible,
    resetQueue,
    linkedAddresses,
    filteredLinkedAddresses,
    toggleExpandRecord,
    expandedRecords,
    toggleDuplicateIsVerified,
    chosenAddressColor,
    chooseAddressColorPopoverRef,
    onUpdateChosenColor,
    onUpdateRecordColor,
    onDeleteAddress,
    deleteButtonIsVisible,
    deleteLinkedAddress,

    isExpandedAllSubrecords,
    toggleExpandAllSubrecords,
  };
}
