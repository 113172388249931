import { IToastLevel, useToast } from '@/hooks/useToast';
import { onMounted } from 'vue';
import { useUnsubs } from '@/hooks/useUnsubs';
import { StandartizeApi } from '@/service/standartizeService';
import { useStore } from 'vuex';

export const useStats = () => {
  const { showToast } = useToast();
  const { sub, unsub } = useUnsubs();
  const store = useStore();

  onMounted(async () => {
    let closeToast: Awaited<ReturnType<typeof showToast>>|null = null;
    const showCadnumSearchStat = async (value: number) => {
      if (!closeToast) {
        closeToast = await showToast({
          label: `Кадастровых номеров в поиске: ${value}`,
          level: IToastLevel.info,
          duration: null,
        });
        sub(closeToast);
      } else {
        store.commit('layout/updateToastById', {
          id: closeToast.id,
          label: `Кадастровых номеров в поиске: ${value}`,
        });
      }
    };

    const intervalId = setInterval(async () => {
      const response = await StandartizeApi.getStats();
      if (!response.status) {
        return;
      }
      const value = response.response.queue_cadnum_search;
      if (!value) {
        if (closeToast) {
          unsub(closeToast);
          closeToast = null;
        }
      } else {
        showCadnumSearchStat(value);
      }
    }, 995000);

    sub(() => clearInterval(intervalId));
  });
};
