import { useUser } from '@/hooks/useUser';
import { StandartizeApi } from '@/service/standartizeService';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { computed } from 'vue';
import { isStandartizeAdmin } from '@/pages/admin/standartize/main/utils';

export const useResetCadnumSearch = () => {
  const { showToast } = useToast();
  const { user } = useUser();
  const resetQueueIsVisible = computed(
    () => isStandartizeAdmin(user.value!.email as string),
  );

  const resetQueue = async () => {
    const response = await StandartizeApi.resetCadnumQueue();
    if (response.status) {
      showToast({
        level: IToastLevel.success,
        label: 'Очередь поиска кадастровых очищена',
      });
    }
  };

  return {
    resetQueueIsVisible,
    resetQueue,
  };
};
