export const addressTree = {
  region_fias: [
    'area_fias', 'city_fias', 'settlement_fias', 'structure_fias', 'street_fias', 'house_fias', 'flat_fias', 'carplace_fias', 'room_fias',
  ],
  area_fias: [
    'city_fias', 'settlement_fias', 'structure_fias', 'street_fias', 'house_fias', 'flat_fias', 'carplace_fias', 'room_fias',
  ],
  city_fias: [
    'settlement_fias', 'structure_fias', 'street_fias', 'house_fias', 'flat_fias', 'carplace_fias', 'room_fias',
  ],
  settlement_fias: [
    'structure_fias', 'street_fias', 'house_fias', 'flat_fias', 'carplace_fias', 'room_fias',
  ],
  structure_fias: [
    'street_fias', 'house_fias', 'flat_fias', 'carplace_fias', 'room_fias',
  ],
  street_fias: [
    'house_fias', 'flat_fias', 'carplace_fias', 'room_fias',
  ],
  house_fias: [
    'flat_fias', 'carplace_fias', 'room_fias',
  ],
  flat_fias: [
    'room_fias',
  ],
};

type FiasType =
  | 'area_fias'
  | 'city_fias'
  | 'settlement_fias'
  | 'structure_fias'
  | 'street_fias'
  | 'house_fias'
  | 'flat_fias'
  | 'carplace_fias'
  | 'room_fias';

export const allLevels: FiasType[] = addressTree.region_fias as FiasType[];
