import {
  computed, Ref, ref, watch,
} from 'vue';
import {
  ActionType, ActiveTable, ActiveTableAction, useActiveTable,
} from '@/components/activeTable/useActiveTable';
import { StandartizeApi } from '@/service/standartizeService';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { mapStandartizeFiltersToQuery } from '@/pages/admin/standartize/main/mapStandartizeFiltersToQuery';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { cadnumSourceOptions } from '@/pages/admin/standartize/main/cadnumSourceDescription/utils';
import { useInitQueryTableParams } from '@/hooks/useInitQueryTableParams';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { RouterKey } from '@core/symbols';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { useContextActions } from '@/pages/admin/standartize/main/useContextActions';
import { StandartizeAddress, StandartizeAddressQuery } from '@/service/standartize/types';

const radioFieldOptions = [
  {
    label: 'Да',
    value: true,
  },
  {
    label: 'Нет',
    value: false,
  },
];

const qualityIntervalOptions = [
  {
    label: '0% - 25%',
    value: { min: 0, max: 25 },
  }, {
    label: '25% - 50%',
    value: { min: 25, max: 50 },
  }, {
    label: '50% - 75%',
    value: { min: 50, max: 75 },
  }, {
    label: '75% - 100%',
    value: { min: 75, max: 100 },
  },
];

export type AddressTable = ActiveTable<
  StandartizeAddressQuery,
  StandartizeAddress,
  'slug'
>;

export type AddressTableFull = AddressTable & {
  keepUpdatedAddresses: Ref<boolean>;
}

export const useAddressTable = () => {
  const { t } = useLocalI18n('admin.standartize.filters');
  const store = useStore();
  const router = useProtectedInject(RouterKey);
  const currentRoute = useRoute();
  const { subscribeToSignal, dispatchSignal } = useSignal();
  const { getContextActions } = useContextActions();

  // не перезапрашивать адреса с применением фильтров после стандартизации
  const keepUpdatedAddresses = ref(true);

  const logOut = () => {
    store.commit('user/setStandartizeToken', null);
    return router.push('/');
  };

  const debtorsTableInitQueryParams = useInitQueryTableParams<StandartizeAddressQuery>();

  const table: AddressTable = useActiveTable<StandartizeAddressQuery, StandartizeAddress, 'slug'>({
    keyField: 'slug',
    // @ts-ignore
    mapFiltersModelToRequest: mapStandartizeFiltersToQuery,
    initFilters: debtorsTableInitQueryParams.filters,
    filters: computed(() => [
      {
        key: 'standardization_file',
        field: 'standardization_file',
        isVisible: false,
        width: 2,
        isResettable: false,
      },
      {
        key: 'name',
        field: 'name',
        type: ActiveFormFieldType.input,
        width: 2,
        options: {
          label: 'Адрес',
        },
      },
      {
        key: 'color',
        field: 'color',
        type: ActiveFormFieldType.select,
        width: 2,
        options: {
          label: 'Цвет',
          state: 'primary',
          allowEmpty: true,
          options: [
            {
              value: 'green',
              label: 'Адрес проверен, стандартизирован корректно',
            }, {
              value: 'red',
              label: 'Адрес проверен, стандартизация невозможна',
            }, {
              value: 'blue',
              label: 'Адрес проверен, кадастровый не найден при ручном поиске',
            }, {
              value: 'true',
              label: 'Данные отсутствуют',
            }, {
              value: 'false',
              label: 'Данные присутствуют',
            },
          ],
        },
      },
      {
        key: 'has_failed_sources',
        field: 'has_failed_sources',
        type: ActiveFormFieldType.checkbox,
        width: 2,
        options: {
          label: 'Проблемные источники кадастровых',
        },
      },
      {
        key: 'cadnum_quality',
        field: 'cadnum_quality',
        type: ActiveFormFieldType.select,
        width: 1,
        options: {
          label: 'Качество кад. номера',
          options: qualityIntervalOptions,
        },
      },
      {
        key: 'cadnum_sources',
        field: 'cadnum_sources',
        type: ActiveFormFieldType.select,
        defaultValue: [],
        width: 1,
        options: {
          label: 'Источник кад. номера',
          options: cadnumSourceOptions,
        },
      },
      {
        key: 'is_setted__changed_reg_court',
        field: 'is_setted__changed_reg_court',
        type: ActiveFormFieldType.radio,
        width: 1,
        label: t('is_setted__changed_reg_court'),
        options: {
          options: radioFieldOptions,
        },
      },
      {
        key: 'is_setted__changed_mir_court',
        field: 'is_setted__changed_mir_court',
        type: ActiveFormFieldType.radio,
        width: 1,
        label: t('is_setted__changed_mir_court'),
        options: {
          options: radioFieldOptions,
        },
      },
      {
        key: 'is_verified__similar',
        field: 'is_verified__similar',
        type: ActiveFormFieldType.radio,
        width: 2,
        label: t('is_verified__similar'),
        options: {
          options: radioFieldOptions,
        },
      },
      // {
      //   key: 'is_setted_cadnum',
      //   field: 'is_setted_cadnum',
      //   type: ActiveFormFieldType.radio,
      //   width: 1,
      //   label: t('is_setted_cadnum'),
      //   options: {
      //     options: radioFieldOptions,
      //   },
      // },
      // {
      //   key: 'is_setted_flat_cadnum',
      //   field: 'is_setted_flat_cadnum',
      //   type: ActiveFormFieldType.radio,
      //   width: 1,
      //   label: t('is_setted_flat_cadnum'),
      //   options: {
      //     options: radioFieldOptions,
      //   },
      // },
      ...([
        'result',
        'main_cadnum',
        'reg_court',
        'mir_court',
        'fssp',
      ].map((key) => {
        const keyPrep1 = `is_verified__${key}`;
        const keyPrep2 = `is_setted__${key}`;
        const field1 = {
          key: keyPrep1,
          field: keyPrep1,
          type: ActiveFormFieldType.radio,
          width: 1,
          label: t(keyPrep1),
          options: {
            options: radioFieldOptions,
          },
        };
        const field2 = {
          key: keyPrep2,
          field: keyPrep2,
          type: ActiveFormFieldType.radio,
          width: 1,
          label: t(keyPrep2),
          options: {
            options: radioFieldOptions,
          },
        };
        return [field1, field2];
      }).reduce((acc, fields) => ([...acc, ...fields]), [] as any)),
      ...([
        'flat_exists',
        'street',
        'house',
        'flat',
        'postalcode',
      ].map((key) => {
        const keyPrep = `is_setted__${key}`;
        const field = {
          key: keyPrep,
          field: keyPrep,
          type: ActiveFormFieldType.radio,
          width: 1,
          label: t(keyPrep),
          options: {
            options: radioFieldOptions,
          },
        };
        return field;
      })),
    ] as unknown as ActiveFormField<{ standardization_file: number } & StandartizeAddress>[]),
    columns: computed(() => ([
      {
        key: 'toggle',
        width: 'minmax(100px, 100px)',
      },
      {
        key: 'status',
        field: 'status',
        label: 'label',
        isRequired: true,
        allowEmpty: true,
        width: 'minmax(100px, 100px)',
      },
      {
        key: 'source',
        field: 'source',
        label: 'Исходный Адрес',
        width: '200px',
        headerClass: 'headerSmall',
      },
      {
        key: 'result',
        field: 'result',
        label: 'Стандартизированный адрес',
        width: '200px',
        headerClass: 'headerSmall',
      },
      {
        key: 'is_verified_result',
        field: 'is_verified_result',
        label: 'Адрес корректный',
        width: 'minmax(100px, 100px)',
        headerClass: 'headerSmall',
      },
      {
        key: 'is_verified_mir_court',
        field: 'is_verified_mir_court',
        label: 'Участок мирового судьи',
        width: 'minmax(200px, 1fr)',
        headerClass: 'headerSmall',
      },
      {
        key: 'is_verified_reg_court',
        field: 'is_verified_reg_court',
        label: 'Участок районного суда',
        width: 'minmax(200px, 1fr)',
        headerClass: 'headerSmall',
      },
      {
        key: 'is_verified_fssp',
        field: 'is_verified_fssp',
        label: 'Участок ФССП',
        width: 'minmax(200px, 1fr)',
        headerClass: 'headerSmall',
      },
      {
        key: 'is_verified_cadnum',
        field: 'is_verified_cadnum',
        label: 'Кадастровый номер',
        width: 'minmax(300px, 1fr)',
        headerClass: 'headerSmall',
      },
      {
        key: 'act',
        field: 'act',
        width: 'minmax(120px, 120px)',
        headerClass: 'headerSmall',
      },
    ])),
    actions: computed(() => ([

      ...getContextActions(table),

      {
        key: 'log_out',
        icon: 'sign-out',
        types: [ActionType.side],
        handler: logOut,
      },
    ]) as Array<ActiveTableAction<any, any>>),

    defaultLimit: ref(50),

    async fetch({ params }) {
      const { response } = await StandartizeApi.address.getList(params);
      return response;
    },

  });

  watch(table.page, async (page) => {
    await router.replace({
      ...currentRoute,
      query: {
        ...(currentRoute.query || {}),
        page,
      },
    });
  });

  watch(table.filtersModel, async (filtersModel) => {
    if (filtersModel) {
      const filtersString = JSON.stringify(
        Object.fromEntries(
          Object.entries(filtersModel).filter(
            ([, v]) => v !== null && v !== undefined,
          ),
        ),
      );
      console.log('filters model', filtersModel, filtersString);
      if (filtersString !== '{}') {
        await router.replace({
          ...currentRoute,
          query: {
            ...(currentRoute.query || {}),
            filters: filtersString,
          },
        });
      } else {
        const { filters, ...query } = currentRoute.query || {};
        await router.replace({
          ...currentRoute,
          query,
        });
      }
    }
  }, { deep: true });

  subscribeToSignal(SignalType.standartizeUpdated, async () => {
    await table.fetchData();
    await dispatchSignal(SignalType.standartizeTableRefetched);
  });

  return {
    ...table,
    keepUpdatedAddresses,
  };
};
