import { ActionType, ActiveTableAction } from '@/components/activeTable/useActiveTable';
import { StandartizeApi } from '@/service/standartizeService';
import { copy2DToClipboard } from '@/utils/string';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { useDialog } from '@/hooks/useDialog';
import { computed, ref } from 'vue';
import { isStandartizeAdmin } from '@/pages/admin/standartize/main/utils';
import { useUser } from '@/hooks/useUser';
import { AddressTable } from '@/pages/admin/standartize/main/useAddressTable';
import { allLevels } from '@/pages/admin/standartize/main/addressLevelTree';
import { AddrFiases, StandartizeAddress } from '@/service/standartize/types';

export function useContextActions() {
  const { confirmDialog } = useDialog();
  const { showToast } = useToast();
  const { user } = useUser();
  const { dispatchSignal } = useSignal();

  const recordsIsDeleting = ref(false);
  const deleteButtonIsVisible = computed(
    () => isStandartizeAdmin(user.value!.email as string),
  );

  return {
    getContextActions: (table: AddressTable) => ([
      {
        key: 'copy_source',
        hint: 'Скопировать исходный адрес',
        types: [ActionType.context],
        async handler({ records }) {
          const list = (records as StandartizeAddress[]).map(
            (record) => [record.source_visual],
          );
          copy2DToClipboard(list);
        },
      },
      {
        key: 'copy_result',
        hint: 'Скопировать стандартизированный адрес',
        types: [ActionType.context],
        async handler({ records }) {
          const list = (records as StandartizeAddress[]).map(
            (record) => [`${record.postalcode}, ${record.result}`],
          );
          copy2DToClipboard(list);
        },
      },
      {
        key: 'copy_result_simple',
        hint: 'Скопировать стандартизированный адрес без элементов',
        types: [ActionType.context],
        async handler({ records }) {
          const list = await Promise.all(
            records.map(
              (record) => simpleAddressView(record),
            ),
          );
          copy2DToClipboard(list);
        },
      },
      {
        key: 'copy_cadnum',
        hint: 'Скопировать кадастровые номера',
        types: [ActionType.context],
        async handler({ records }) {
          const list = (records as StandartizeAddress[])
            .filter(
              (record) => record?.main_cadnum?.cadnum,
            )
            .map(
              (record) => [record?.main_cadnum?.cadnum],
            );
          copy2DToClipboard(list);
        },
      },
      {
        key: 'color',
        hint: 'Закрасить выделенные',
        types: [ActionType.context],
        async handler({ records }) {
          await dispatchSignal(
            SignalType.standartizeColorAddressesList,
            records,
          );
        },
      },
      ...(deleteButtonIsVisible.value ? [{
        key: 'delete',
        hint: 'Удалить выбранные',
        types: [ActionType.context],
        async handler({ records, selectedItems }, record, tableContext) {
          if (recordsIsDeleting.value) {
            return;
          }
          const confirmResult = await confirmDialog({
            title: 'Удалить выбранные адреса?',
            message: 'Подтвердите удаление',
            confirmLabel: 'Удалить',
            cancelLabel: 'Отмена',
          });
          confirmResult.closeDialog();
          if (!confirmResult.result) {
            return;
          }
          const closeToast = await showToast({
            label: 'Удаление адресов...',
            level: IToastLevel.info,
          });
          recordsIsDeleting.value = true;
          await Promise.all(
            selectedItems.map((slug: string) => StandartizeApi.address.deleteItem(slug)),
          );
          await closeToast();
          if (tableContext) {
            tableContext.selectedItemIdList.value = [];
            tableContext.allOnAllPagesSelected.value = false;
          }
          table.records.value = table.records.value.filter(
            ({ slug }) => !selectedItems.includes(slug),
          );
          recordsIsDeleting.value = false;
        },
      } as ActiveTableAction<any, any>] : []),

    ]) as Array<ActiveTableAction<any, any>>,

  };

}

async function simpleAddressView(address: StandartizeAddress): Promise<string[]> {
  const addressResponse = await StandartizeApi.address.getItem(address.slug);
  if (addressResponse.response) {
    const detailed: AddrFiases = addressResponse.response as unknown as AddrFiases;
    return [
      allLevels
        .map((level) => {
          const item = detailed[level];
          return item && item.name
            .replace('д ', '')
            .replace('кв ', '');
        })
        .filter((item) => !!item)
        .join(', '),
    ];
  }
  return [];
}
