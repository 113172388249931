import { computed, Ref } from 'vue';
import { StandartizeAddress } from '@/service/standartize/types';

export function useIsVerified(addressRecords: Ref<StandartizeAddress[]>) {

  const isVerifiedPage = computed(
    () => addressRecords.value.every(
      (address) => address.is_verified_result,
    ),
  );

  const isVerifiedPageMirCourt = computed(
    () => addressRecords.value.every(
      (address) => address.is_verified_mir_court,
    ),
  );

  const isVerifiedPageRegCourt = computed(
    () => addressRecords.value.every(
      (address) => address.is_verified_reg_court,
    ),
  );

  const isVerifiedPageFssp = computed(
    () => addressRecords.value.every(
      (address) => address.is_verified_fssp,
    ),
  );

  const isVerifiedPageCadnum = computed(
    () => addressRecords.value.every(
      (address) => address.is_verified_cadnum,
    ),
  );

  return {
    isVerifiedPage,
    isVerifiedPageMirCourt,
    isVerifiedPageRegCourt,
    isVerifiedPageFssp,
    isVerifiedPageCadnum,
  };

}
