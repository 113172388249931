import { StandartizeApi } from '@/service/standartizeService';
import { StandartizeCadnumLevel } from '@/store/modules/user';
import { Ref } from 'vue';
import { StandartizeAddress } from '@/service/standartize/types';

export const useCadnumSearch = (
  filterLevels: Ref<StandartizeCadnumLevel[] | undefined>,
) => async (
  address: StandartizeAddress,
) => {
  const addressResponse = await StandartizeApi.address.getItem(address.slug);
  if (!addressResponse.status) {
    return [];
  }
  return filterLevels.value !== null
    ? addressResponse.response.cadnum_suggest.filter(
      (cadnum) => (
        filterLevels.value as StandartizeCadnumLevel[]
      ).includes(cadnum.level as StandartizeCadnumLevel),
    )
    : addressResponse.response.cadnum_suggest;
};
