import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { Ref } from 'vue';
import { getRandomString } from '@/utils/string';
import { useToast } from '@/hooks/useToast';
import { CourtType } from '@/service/standartize/jurisdiction-types';

export type AddAddressesPayload = {
  activeTab: Ref<CourtType>;
  settlement: Ref<number | undefined>;
  courtId: Ref<string>;
}

export type UnRefedPayload = {
  settlement: number;
  activeTab: CourtType;
  courtId: string;
}

export function useAddAddressesDialog(
  { refetch }: { refetch: () => Promise<void> },
  payload: AddAddressesPayload,
) {

  const { showPureDangerToast } = useToast();
  const { showDialog } = useDialog();
  const { subscribeToSignalOnce } = useSignal();
  const dialogId = getRandomString();

  const showAddAddressesDialog = async () => {
    if (!payload.settlement.value) {
      await showPureDangerToast({
        params: { message: 'Выберите регион и населенный пункт' },
      });
      return;
    }
    if (!payload.courtId.value) {
      await showPureDangerToast({
        params: { message: 'Выберите судебный участок' },
      });
      return;
    }

    await showDialog({
      id: dialogId,
      component: IDialogComponent.addAddressesToCourt,
      addInRoute: false,
      payload: {
        ...payload,
      },
    });

    subscribeToSignalOnce(SignalType.dialogClosed, async (payload: { id: string }) => {
      if (dialogId === payload.id) {
        await refetch();
      }
    });
  };

  return { showAddAddressesDialog };
}
