import { computed, Ref, ref } from 'vue';
import { StandartizeApi } from '@/service/standartizeService';
import { useSearchCommonSimple } from '@/pages/admin/standartize/main/filters/useSearchCommon';
import { AddrObj } from '@/service/standartize/types';

export const useRegions = () => {

  const region = ref<string | null>(null);

  const {
    records: regions,
  } = useSearchCommonSimple<AddrObj & { code: string }>('regions-list', async () => StandartizeApi.addrObj.getList({
    limit: 100,
    page: 1,
    filters: {
      aolevel: '1',
    },
  }).then((obj) => {
    const { response } = obj;
    return response.results.map((r: any) => ({
      ...r,
      code: r.region.substring(0, 2),
    }))
      .sort((a: AddrObj, b: AddrObj) => a.full_name.localeCompare(b.full_name));
  }));

  const regionCode = computed(() => {
    const code = regions.value?.find(
      (r) => r.objectid === region.value,
    )?.code;
    return code ? code.substring(0, 2) : null;
  });

  return {
    region,
    regions: regions as Ref<AddrObj[]>,
    regionCode,
  };
};
